import React from 'react';
import PropTypes from 'prop-types';
import { CupertinoPane } from 'cupertino-pane';
import ScrollTopButton from '../ScrollTopButton';

import './BottomSheet.css';


class BottomSheet extends React.Component {    
    constructor(props) {
        super(props);
        this.state = {
            pane: null,
            paneRef: null,
        };

        this.onChange = this.onChange.bind(this);
        this.open = this.open.bind(this);
    }

    onChange(isOpen) {
        this.props.onChange(isOpen);
    }

    componentDidMount() {
        const pane = new CupertinoPane(`#${this.props.id}`, {
            parentElement: `.pane-parent.${this.props.id}`,
            initialBreak: this.props.initialBreak,
            // fastSwipeClose: !!this.props.onDismiss,
            fastSwipeSensivity: 3,
            buttonDestroy: !!this.props.onDismiss,
            bottomOffset: 0,
            clickBottomOpen: this.props.clickBottomOpen,
            onWillDismiss: () => {
                if (this.props.onDismiss) {
                    this.props.onDismiss();
                }
            },
            // fitHeight: true,
            // maxFitHeight: window.innerHeight - this.props.offsetTop,
            fitScreenHeight: false,
            lowerThanBottom: true,
            upperThanTop: true,
            touchAngle: 50,
            fastSwipeClose: false,
            // pushElement: '#map',
            // pushMinHeight: window.innerHeight * 2/3,
            onTransitionEnd: () => {
                const open = this.state.pane && this.state.pane.currentBreak() !== 'bottom';
                this.props.onChange(open);
                this.props.onTransitionEnd();
                console.log(this.state.pane.currentBreak());
            },
            // followerElement: '.mapbox-ctrl-bottom-left',
            bottomClose: this.props.bottomClose,
            backdrop: this.props.backdrop,

            breaks: this.getBreakpoints(),
        });
        
        if (!this.props.hidden) {
            pane.present({ animate: true });
        }

        this.setState({
            pane,
        });

        window.addEventListener("resize", () => this.onResize(250));
        this.headerRef.addEventListener("touchmove", e => e.preventDefault());
    }

    getBreakpoints() {
        return {
            top: {
                enabled: true,
                height: window.innerHeight - this.props.offsetTop,
                bounce: true,
            },
            middle: {
                enabled: !this.props.fullScreen,
                height: window.innerHeight * 2/3,
                bounce: true,
            },
            bottom: {
                enabled: true,
                height: this.props.overflowHeight + this.props.offsetTop,
                bounce: true,
            },
        };
    }

    open() {
        const { pane } = this.state;

        if (pane) {
            pane.moveToBreak('top');
        }
    }

    componentDidUpdate(prevProps) {
        const { pane } = this.state;
        const { hidden } = this.props;

        if (pane && hidden && !prevProps.hidden) {
            pane.hide();
        } else if (pane && !hidden && prevProps.hidden) {
            const isHidden = pane.isHidden();
            if (isHidden) {
                this.state.pane.setBreakpoints(this.getBreakpoints());
                pane.moveToBreak(pane.currentBreak());
            } else if (isHidden === null) {
                pane.present({ animate: true });
            }
        }
    }

    onResize(delay = 0) {
        const { pane } = this.state;
        const hidden = pane.isHidden();
        console.log(`hidden: ${typeof hidden}`);
        if (pane) {
            setTimeout(() => {
                console.log("Setting breakpoints");
                pane.setBreakpoints(this.getBreakpoints());
                console.log(`hidden: ${hidden}`);
                if (hidden) {
                    setTimeout(() => {
                        console.log("Re-hiding");
                        pane.hide();
                    }, delay);
                }
            }, delay);
        }
    }

    render() {
        const { id, title, subHeader: SubHeader, subHeaderProps, subSubHeader, scrollTopButton, children, headerBorderOnBottom, headerBorder } = this.props;
        const { paneRef } = this.state;

        return (
            <div className={`pane-parent ${id}`}>
                <div className="cupertino-pane" id={id} ref={ele => !this.state.paneRef && this.setState({ paneRef: {current: ele} })}>
                    <div className="sheet-body">
                        <div
                            className="body-header"
                            ref={ele => this.headerRef = ele}
                        >
                            <div className="title-row">
                                { title }
                            </div>
                            {SubHeader ? <SubHeader {...subHeaderProps} openBottomSheet={() => this.open()} /> : null}
                        </div>
                        { headerBorder && headerBorderOnBottom && <div className="body-header-border"></div> }
                        { headerBorder && !headerBorderOnBottom && <div hide-on-bottom="true" className="body-header-border"></div> }
                        <div className="body-subheader">
                            { subSubHeader }
                        </div>
                        { scrollTopButton && paneRef ?
                            <ScrollTopButton
                                scrollContainerRef={paneRef}
                            />
                        : null }
                        <div className="body-open" overflow-y="true">
                            { children }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BottomSheet.defaultProps = {
    offsetTop: 20,
    overflowHeight: 192,
    fullScreen: false,
    onDismiss: null,
    clickBottomOpen: false,
    shadowTip: null,
    zIndex: 100,
    onTransitionEnd: () => {},
    onChange: () => {},
    scrollTopButton: false,
    hidden: false,
    initialBreak: 'bottom',
    subHeaderProps: {},
    headerBorder: true,
    headerBorderOnBottom: false,
    bottomClose: false,
    backdrop: false,
};

BottomSheet.propTypes = {
    id: PropTypes.string.isRequired,
    overflowHeight: PropTypes.number.isRequired,
    offsetTop: PropTypes.number,
    clickBottomOpen: PropTypes.bool,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    subHeader: PropTypes.any,  // Component
    subHeaderProps: PropTypes.object,
    children: PropTypes.node,
    fullScreen: PropTypes.bool,
    onDismiss: PropTypes.func,
    shadowTip: PropTypes.bool,
    onTransitionEnd: PropTypes.func,
    onChange: PropTypes.func,
    scrollTopButton: PropTypes.bool,
    hidden: PropTypes.bool,
    initialBreak: PropTypes.string,
    headerBorder: PropTypes.bool,
    headerBorderOnBottom: PropTypes.bool,
    bottomClose: PropTypes.bool,
    backdrop: PropTypes.bool,
};


export default BottomSheet;
