import PropTypes from 'prop-types';
import Badge from 'react-bootstrap/Badge';

import './SummitBadges.css';

const SummitClassificationBadge = ({ cls, margin, plural }) => (
    <Badge pill variant={cls.toLowerCase()} className={margin ? "summit-badge" : "summit-badge no-margin"} >{ cls + (plural ? 's' : '') }</Badge>
);

SummitClassificationBadge.defaultProps = {
    margin: true,
    plural: false,
}

SummitClassificationBadge.propTypes = {
    cls: PropTypes.string.isRequired,
    margin: PropTypes.bool,
    plural: PropTypes.bool,
}

export default SummitClassificationBadge;