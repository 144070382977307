import PropTypes from 'prop-types';
import { Media } from 'react-breakpoints';
import BottomSheet from '../BottomSheet';
import Panel from '../Panel';


const ResponsivePanel = ({ children, panelProps, bottomSheetProps }) => {

    return (
        <Media>
            {({ breakpoints, currentBreakpoint }) => breakpoints[currentBreakpoint] > breakpoints.mobile ? (
                <Panel
                    {...panelProps}
                >
                    { children }
                </Panel>
            ) : (
                <BottomSheet
                    {...bottomSheetProps}
                >
                    { children }
                </BottomSheet>
            )}
        </Media>
    )
};

ResponsivePanel.defaultProps = {
    children: <></>,
    panelProps: {},
    bottomSheetProps: {},
};

ResponsivePanel.propTypes = {
    children: PropTypes.node,
    panelProps: PropTypes.object,
    bottomSheetProps: PropTypes.object,
};

export default ResponsivePanel;
