import { atom, selector } from 'recoil';
import Profile from '../profile';

export const profileState = atom({
    key: 'profileState',
    default: Profile.getProfile(),
});

export const baggedSummitsState = selector({
    key: 'baggedSummitsState',
    get: ({ get }) => {
        const profile = get(profileState);
        return profile.bagged;
    },
});
