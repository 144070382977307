import PropTypes from 'prop-types';
import SummitClassificationBadge from '../SummitBadges/SummitClassificationBadge';


const BaggingCounts = ({ baggedByType, type, total }) => (
    <div className="count-detail">
        <div>
            <big>{ baggedByType[type] }</big>
            <span className="total"> / { total }</span>
        </div>
        
        <div>
            <SummitClassificationBadge margin={false} cls={type} plural />
        </div>
    </div>
)

BaggingCounts.propTypes = {
    baggedByType: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
}


export default BaggingCounts;
