import React from 'react';
import PropTypes from 'prop-types';
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import { IoOptions, IoSwapVertical } from 'react-icons/io5';

import FilterControls from './FilterControls';
import ToggleButtons from '../ToggleButtons';

import {
    byDistance,
    byDrivingTime,
    byAscent,
    byHeight,
    byWalkingTime,
    byAlphabetical,
} from './sorting';

import './SortingControls.css';


const sortingOptions = {
    driveTime: {
        name: "Drive to Start",
        sortFn: byDrivingTime,
        orderText: {
            ascending: "short",
            descending: "long",
        },
    },
    summitHeight: {
        name: "Summit Height",
        sortFn: byHeight,
        defaultDecreasing: true,
        orderText: {
            ascending: "Low",
            descending: "High",
        },
    },
    walkDistance: {
        name: "Walk Distance",
        sortFn: byDistance,
        orderText: {
            ascending: "Short",
            descending: "Long",
        },
    },
    ascent: {
        name: "Walk Ascent",
        sortFn: byAscent,
        orderText: {
            ascending: "Less",
            descending: "More",
        },
    },
    time: {
        name: "Walk Time",
        sortFn: byWalkingTime,
        orderText: {
            ascending: "Short",
            descending: "Long",
        },
    },
    alphabetical: {
        name: "Summit Name",
        sortFn: byAlphabetical,
        orderText: {
            ascending: "A - Z",
            descending: "Z - A",
        },
    },
    // Left out to keep it simple
    // 
    // grade: {
    //     name: "Path Grade",
    //     sortFn: byGrade,
    //     orderText: {
    //         ascending: "easiest",
    //         descending: "hardest",
    //     },
    // },
    // bog: {
    //     name: "Bogginess",
    //     sortFn: byBogFactor,
    //     orderText: {
    //         ascending: "least",
    //         descending: "most",
    //     },
    // },
};

const sortingOptionsByName = Object.values(sortingOptions).reduce((acc, cur) => ({
    ...acc,
    [cur.name]: cur,
}), {});

const getOrderedSortingOptions = (drivingTimeAvailable) => {
    const orderedSortingOptions = Object.values(sortingOptions);
    if (drivingTimeAvailable === false) {
        orderedSortingOptions.shift();
    }
    return orderedSortingOptions;
}

class SortingControls extends React.Component {
    constructor(props) {
        super(props);

        const orderedSortingOptions = getOrderedSortingOptions(this.props.drivingTimeAvailable);

        this.state = {
            selected: orderedSortingOptions[0],
            decreasing: !!orderedSortingOptions[0].defaultDecreasing,
            orderedSortingOptions,
            filters: [],
        };

        this.onChange = this.onChange.bind(this);
        this.onOrderToggle = this.onOrderToggle.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
    }

    componentDidMount() {
        this.props.onChange({
            selected: this.state.selected,
            decreasing: this.state.decreasing,
            filters: this.state.filters,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.decreasing !== prevState.decreasing
            || this.state.selected !== prevState.selected
            || this.state.filters !== prevState.filters) {
            this.props.onChange({
                selected: this.state.selected,
                decreasing: this.state.decreasing,
                filters: this.state.filters
            });
        }
        if (prevProps.drivingTimeAvailable !== this.props.drivingTimeAvailable) {
            const orderedSortingOptions = getOrderedSortingOptions(this.props.drivingTimeAvailable);
            this.setState({
                orderedSortingOptions,
                selected: orderedSortingOptions[0],
                decreasing: !!orderedSortingOptions[0].defaultDecreasing,
            });
        }
    }

    onChange(name) {
        const selected = sortingOptionsByName[name];

        this.setState({
            sortFn: selected.sortFn,
            decreasing: !!selected.defaultDecreasing,
            selected,
        });
    }

    onFilterChange(filters) {
        this.setState({
            filters,
        });
    }

    onOrderToggle(decreasing) {
        this.setState({
            decreasing,
        });
    }

    renderSortingOption(sortingOption, key) {
        const { name, orderText, defaultDecreasing } = sortingOption;
        const { selected } = this.state;
        const active = sortingOption.name === selected.name;

        const buttonConfigs = [
            {
                label: orderText.ascending,
                value: false,
                variant: "outline-info",
            }, {
                label: orderText.descending,
                value: true,
                variant: "outline-info",
            },
        ];
        
        if (active && defaultDecreasing) {
            buttonConfigs.reverse();
        }

        return (
            <ListGroup.Item
                key={key}
                action
                onClick={(e) => {
                    e.stopPropagation();
                    this.onChange(name);
                }}
                className="sorting-option-row"
                active={active}
            >
                <div>{name}</div>
                { active ? 
                    <ToggleButtons
                        name="order-buttons"
                        onChange={this.onOrderToggle}
                        selected={this.state.decreasing}
                        buttonConfigs={buttonConfigs}
                    />
                : <></> }
            </ListGroup.Item>

        );
    }

    render() {
        const { orderedSortingOptions } = this.state;
        const { children, disabled, drivingTimeAvailable } = this.props;

        return (
            <Accordion>
                <div className="sort-controls-row">
                    { children }
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Accordion.Toggle
                            as={Button}
                            disabled={disabled}
                            eventKey="0"
                            className="sort-controls-toggle"
                            variant="link"
                        >
                            <IoOptions
                                size="24px"
                                style={{marginRight: ".25rem"}}
                            />
                            Filter
                        </Accordion.Toggle>
                        <Accordion.Toggle
                            as={Button}
                            disabled={disabled}
                            eventKey="1"
                            className="sort-controls-toggle"
                            variant="link"
                        >
                            <IoSwapVertical
                                size="24px"
                                style={{marginRight: ".25rem"}}
                            />
                            Sort
                        </Accordion.Toggle>
                    </div>
                </div>
                <Accordion.Collapse eventKey="0">
                    <FilterControls
                        drivingTimeAvailable={drivingTimeAvailable}
                        onChange={this.onFilterChange}
                    />
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="1">
                    <>
                        <ListGroup className="sorting-options-list">
                            { orderedSortingOptions.map((sortingOption, idx) => this.renderSortingOption(sortingOption, idx)) }
                        </ListGroup>
                    </>
                </Accordion.Collapse>
            </Accordion>
        );
    }
}

SortingControls.defaultProps = {
    disabled: false,
    drivingTimeAvailable: null,
    onChange: () => {},
};

SortingControls.propTypes = {
    disabled: PropTypes.bool,
    drivingTimeAvailable: PropTypes.bool,
    onChange: PropTypes.func,
};

export default SortingControls;
