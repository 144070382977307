import React, { useState } from 'react';
import { IoHelp } from 'react-icons/io5';
import CustomControl from './CustomControl';
import LandingPanel from '../../LandingPanel';


class HelpControl extends CustomControl {
    render() {
        return (
            <HelpButton />
        )
    }
}

const HelpButton = () => {
    const [show, setShow] = useState(false);

    return (
        <>
            <div className="custom-map-control">
                <button onClick={() => setShow(true)}>
                    <IoHelp className="mapbox-ctrl-icon" size="18px" />
                </button>
            </div>
            <LandingPanel
                show={show}
                onClose={() => setShow(false)}
            />
        </>
    )
}


export default HelpControl;
