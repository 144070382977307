import PropTypes from 'prop-types';
import './MapMarker.css';


const MapMarker = (props) => (
    <div
        className={ props.circle ? "map-marker circle" : "map-marker" }
    >
        <span
            style={{
                height: props.size,
                width: props.size,
                borderWidth: props.borderWidth,
                backgroundColor: props.backgroundColor,
            }}
        >
            { props.content
                ? <b>{ props.content }</b>
                : <div></div> }
        </span>
    </div>
);

MapMarker.defaultProps = {
    content: '',
    circle: false,
    size: 30,
    borderWidth: 2,
    backgroundColor: '#499F36',
};

MapMarker.propTypes = {
    content: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node]),
    circle: PropTypes.bool,
    size: PropTypes.number,
    borderWidth: PropTypes.number,
    backgroundColor: PropTypes.string,
};

export default MapMarker;