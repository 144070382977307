import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { withBreakpoints } from 'react-breakpoints';
import { withRouter } from 'react-router-dom';

import LandingPanel from './components/LandingPanel';
import SummitBottomSheet from './components/SummitBottomSheet';
import SearchPanel from './components/SearchPanel';
import Profile from './components/Profile';
import BaggedSummits from './components/Profile/BaggedSummits';
import FavouriteSummits from './components/Profile/FavouriteSummits';
import SummitClassificationFilters from './components/SummitClassificationFilters'
import Map from './components/Map';
import Nav, { NAV_KEYS, SUB_NAV_KEYS } from './components/Nav';

import { fetchSummits, fetchWalks, fetchTravelTimes } from './services/walksApi';
import { getWalksForSummit } from './utils'; 

import { filterSummits } from './components/Search';
import withProfile from './hocs/withProfile';

import './App.css';

const allSummitFilters = ['Munro', 'Corbett', 'Graham'];

const urlParamToFilter = {
    munros: ['Munro'],
    corbetts: ['Corbett'],
    grahams: ['Graham'],
    all: allSummitFilters
}

class App extends React.Component {
    constructor(props) {
        super(props);
        const summitFilters = urlParamToFilter[props.match.params.summitClass] || allSummitFilters;
        this.state = {
            summitFilters,
            showAllSummits: summitFilters === allSummitFilters,
            summits: null,
            walks: null,
            searchOptions: {
                query: "",
                filters: [],
            },
            location: null,
        };
        console.log(props);
        
        this.onSelectSummit = this.onSelectSummit.bind(this);
        this.onSummitFilterChange = this.onSummitFilterChange.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onBaseLocationChange = this.onBaseLocationChange.bind(this);
    }

    componentDidMount() {
        this.getLocation();
        this.loadSummits();
        this.loadWalks();
    }
    
    getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;

                if (isNaN(latitude) || isNaN(longitude)) {
                    return;
                }

                this.setState({
                    location: { latitude, longitude },
                });
            }, (error) => {
                console.log(error);
            });
        } else {
            console.log("No location available");
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { location } = this.state;

        if (location && location !== prevState.location) {
            this.loadTravelTimes(location);
        }
    }

    loadSummits() {
        fetchSummits().then((summits) => {
            const summitIdToReadableId = summits.reduce((acc, cur) => {
                acc[cur.id] = cur.readable_id;
                return acc;
            }, {});
            const readableIdToSummitId = Object.entries(summitIdToReadableId).reduce((acc, [k, v]) => {
                acc[v] = k;
                return acc;
            }, {});
            this.setState({
                summits,
                summitIdToReadableId,
                readableIdToSummitId,
            });
        });
    }

    loadWalks() {
        fetchWalks({
            classification: allSummitFilters,
        }).then((walksData) => {
            const walks = walksData.reduce((acc, walk) => {
                acc[walk.id] = walk;
                return acc;
            }, {});
            const { travelTimes } = this.state;

            if (walks && travelTimes) {
                this.setState({
                    walks: this.mergeTravelTimes(walks, travelTimes),
                });
            } else {
                this.setState({
                    walks,
                });
            }
            console.log("Loaded walks");
        });
    }
    
    loadTravelTimes(location) {
        fetchTravelTimes(location, {
            classification: allSummitFilters,
        }).then((travelTimes) => {
            const { walks } = this.state;
            if (walks && travelTimes) {
                this.setState({
                    travelTimes,
                    walks: this.mergeTravelTimes(walks, travelTimes),
                });
            } else {
                this.setState({
                    travelTimes,
                });
            }
            console.log("Loaded travel times");
        });
    }

    mergeTravelTimes(walks, travelTimes) {
        if (!walks || !travelTimes) return null;

        const walksCopy = Object.assign({}, walks);

        Object.entries(travelTimes).forEach(([walkId, travelData]) => {
            walksCopy[walkId] = {...walksCopy[walkId], ...travelData};
        });

        return walksCopy;
    }
    
    onSelectSummit(summitId) {
        const { history } = this.props;
        const { summitIdToReadableId } = this.state;

        if (summitId) {
            const readableId = summitIdToReadableId[summitId];
            history.push(`/${readableId}`, {
                from: history.location.state?.from || history.location.pathname,
                navKey: this.getNavKey(),
            });
        } else {
            history.push(history.location.state?.from || '/search');
        }
    }
    
    onSummitFilterChange(summitFilters) {
        console.log(summitFilters)
        if (Array.isArray(summitFilters)) {
            this.setState({
                summitFilters,
                showAllSummits: false,
            });
        } else if (summitFilters) {
            this.setState({
                showAllSummits: true,
                summitFilters: allSummitFilters,
            });
        }
    }

    filterSummit(summit, query) {
        if (query === "") return true;
        if (summit.alternative_names.length > 0) {
            if (summit.alternative_names.some(name => name.toLowerCase().includes(query.toLowerCase()))) {
                return true;
            }
        }
        return summit.name.toLowerCase().includes(query.toLowerCase());
    }

    filterSummits() {
        if (!this.state.summits || !this.state.walks) return [];
        const { query, filters, sortSummitsOptions, decreasing } = this.state.searchOptions;

        console.log("Getting search results");
        let results = this.state.summits.filter(summit => this.filterSummit(summit, query));
        if (filters.length > 0)
            results = filterSummits(results, this.state.walks, filters);
        if (sortSummitsOptions)
            results = sortSummitsOptions.sortFn(results, this.state.walks, decreasing);
        return results;
    }

    onSearchChange(searchOptions) {
        this.setState({
            searchOptions,
        });
    }
    
    onBaseLocationChange(location) {
        this.setState({
            location,
        });
    }

    getNavKey() {
        return this.props.history.location.state?.navKey || this.props.navKey;
    }

    getProfileSummits() {
        const { summits } = this.state;
        const { filterBaggedAndFavourites, filterBagged, filterFavourites } = this.props;

        switch (this.props.subNavKey) {
            case SUB_NAV_KEYS.PROFILE:
                return filterBaggedAndFavourites(summits);
            case SUB_NAV_KEYS.BAGGED:
                return filterBagged(summits)
            case SUB_NAV_KEYS.FAV:
                return filterFavourites(summits);
            default:
                return this.state.summits;
        }
    }

    getSummits() {
        if (!this.state.summits) return [];

        switch (this.getNavKey()) {
            case NAV_KEYS.MAP:
                return this.state.summits;
            case NAV_KEYS.SEARCH:
                return this.filterSummits().filter(summit => summit.classification.filter(category => this.state.summitFilters.includes(category)).length > 0);
            case NAV_KEYS.PROFILE:
                return this.getProfileSummits();
            default:
                return this.state.summits;
        }
    }

    render() {
        const loading = !this.state.summits || !this.state.walks;

        const selectedSummitId = this.props.match.params.summitId && this.state.readableIdToSummitId && this.state.walks
            ? this.state.readableIdToSummitId[this.props.match.params.summitId]
            : null;
        
        const summits = this.getSummits();

        const selectedSummit = selectedSummitId
            ? summits.find(summit => summit.id === selectedSummitId)
            : null;
        
        const { breakpoints, currentBreakpoint, navKey, subNavKey, profile, setSeenLandingPage } = this.props;

        const desktopView = breakpoints[currentBreakpoint] > breakpoints.mobile;


        const searchPanel = (
            <>
                <SearchPanel
                    summits={summits}
                    walks={this.state.walks}
                    onSelectSummit={this.onSelectSummit}
                    onSearchChange={this.onSearchChange}
                    hidden={navKey !== NAV_KEYS.SEARCH || selectedSummit !== null}
                >
                    <SummitClassificationFilters
                        onChange={this.onSummitFilterChange}
                        allChecked={this.state.showAllSummits}
                        default={this.state.summitFilters}
                    />
                </SearchPanel>
                { selectedSummit ? (
                    <SummitBottomSheet
                        summit={selectedSummit}
                        walks={getWalksForSummit(this.state.walks, selectedSummit)}
                        onDismiss={() => this.onSelectSummit(null)}
                        hidden={false}
                    />
                ) : null }
            </>
        );

        const profilePanel = (
            <Profile
                walks={this.state.walks}
                summits={this.state.summits}
                onSelectSummit={this.onSelectSummit}
                hidden={subNavKey !== "profile"}
            />
        )

        const baggedPanel = (
            <BaggedSummits
                summits={this.state.summits}
                walks={this.state.walks}
                hidden={subNavKey !== "bagged"}
                onSelectSummit={this.onSelectSummit}
            />  
        );


        const favouritesPanel = (
            <FavouriteSummits
                summits={this.state.summits}
                walks={this.state.walks}
                hidden={subNavKey !== "favourites"}
                onSelectSummit={this.onSelectSummit}
            />  
        );


        return (
            <>
                <Helmet>
                    <title>Munro Bagging</title>
                    <meta
                        name="description"
                        content="Find your next mountain hike in Scotland"
                    />
                </Helmet>
                <div className="mainwrapper">
                    
                    <div className="flex-row">
                        <Nav
                            activeKey={this.getNavKey()}
                        />
                        <LandingPanel show={!profile.seenLandingPage} onClose={() => setSeenLandingPage(true)} />
                        { searchPanel }
                        { profilePanel }
                        { baggedPanel }
                        { favouritesPanel }
                        <Map
                            id="map"
                            loading={loading}
                            summits={summits}
                            walks={this.state.walks}
                            focussed={selectedSummitId}
                            onSelectSummit={this.onSelectSummit}
                            bottomOffset={desktopView ? 0 : 165}
                            drivingStartPoint={this.state.location}
                            onDrivingStartPointChange={this.onBaseLocationChange}
                        />
                    </div>
                </div>
            </>
        );
    }
};

App.propTypes = {
    filterBaggedAndFavourites: PropTypes.func.isRequired,
}

export default withProfile(withRouter(withBreakpoints(App)));
