import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import ResponsivePanel from '../ResponsivePanel';
import SummitList from '../SummitList';
import Header from '../Text/Header';

import withProfile from '../../hocs/withProfile';

import './FavouriteSummits.css';


const FavouriteSummits = ({ filterFavourites, history, summits, walks, onSelectSummit, hidden }) => {

    const favourites = filterFavourites(summits);

    return (
        <ResponsivePanel
            panelProps={{
                stickyHeader: <Header content="Favourites" />,
                hidden,
                flush: true,
            }}
            bottomSheetProps={{
                id: 'favourites',
                shadowTip: false,
                title: <Header content="Favourites" />,
                fullScreen: true,
                initialBreak: 'top',
                hidden,
                overflowHeight: 137,
                onDismiss: () => history.push('/profile'),
                // bottomClose: true,
            }}
        >
            <div hide-on-bottom="true" className="favourites-panel">
                <SummitList
                    loading={!summits}
                    summits={favourites}
                    walks={walks}
                    onSelectSummit={onSelectSummit}
                    flush
                />
            </div>
        </ResponsivePanel>
    );
};

FavouriteSummits.defaultProps = {
    summits: [],
    walks: {},
    onSelectSummit: () => {},
    hidden: false,
};

FavouriteSummits.propTypes = {
    filterFavourites: PropTypes.func.isRequired,
    summits: PropTypes.array,
    walks: PropTypes.object,
    onSelectSummit: PropTypes.func,
    hidden: PropTypes.bool,
    history: PropTypes.object.isRequired,
};

export default withRouter(withProfile(FavouriteSummits));
