import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { profileState } from '../state/recoil';

import Profile from '../profile';


const withProfile = WrappedComponent => props => {
    const [profile, setProfile] = useRecoilState(profileState);

    const bagSummit = useCallback((id, date) => {
        setProfile(Profile.bagSummit(profile, id, date));
    }, [profile, setProfile]);

    const unbagSummit = useCallback((id) => {
        setProfile(Profile.unbagSummit(profile, id));
    }, [profile, setProfile]);

    const isBagged = useCallback((id) => Profile.isBagged(profile, id), [profile]);

    const addFavouriteSummit = useCallback((id) => {
        setProfile(Profile.addFavouriteSummit(profile, id));
    }, [profile, setProfile]);

    const removeFavouriteSummit = useCallback((id) => {
        setProfile(Profile.removeFavouriteSummit(profile, id));
    }, [profile, setProfile]);

    const setSeenLandingPage = useCallback((seen) => {
        setProfile(Profile.setSeenLandingPage(profile, seen));
    }, [profile, setProfile]);

    const isFavourite = useCallback((id) => Profile.isFavourite(profile, id), [profile]);
    const countBaggedByType = useCallback((summits) => Profile.countBaggedByType(profile, summits), [profile]);
    const filterFavourites = useCallback((summits) => Profile.filterFavourites(profile, summits), [profile]);
    const filterBagged = useCallback((summits) => Profile.filterBagged(profile, summits), [profile]);
    const filterBaggedAndFavourites = useCallback((summits) => Profile.filterBaggedAndFavourites(profile, summits), [profile]);

    const profileProps = {
        profile,
        bagSummit,
        unbagSummit,
        isBagged,
        addFavouriteSummit,
        removeFavouriteSummit,
        isFavourite,
        countBaggedByType,
        filterFavourites,
        filterBagged,
        filterBaggedAndFavourites,
        setSeenLandingPage,
    };

    return <WrappedComponent {...props} {...profileProps} />;
};


export default withProfile;