import React from 'react';
import PropTypes from 'prop-types';

import Card from 'react-bootstrap/Card';

import HoverTooltip from '../HoverTooltip';
import WeatherIcon from './WeatherIcon';
import { isToday } from '../../utils';

import './Weather.css';


const weekdays = [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
];

const formatDay = (date) => {
    if (isToday(date)) {
        return "Today";
    }
    return weekdays[date.getDay()];
}


const formatTemp = (temp) => {
    return Math.round(temp).toString() + '°';
}


const WeatherDay = ({ day, idx }) => {
    const { dt, temp, weather } = day;
    const date = new Date(dt * 1000);

    return (
        <HoverTooltip name={weather[0].description} id={`weather-day-${idx}`}>
            <div className="weather-day">
                <span className="day-name">{ formatDay(date) }</span>
                    <WeatherIcon id={weather[0].id} icon={weather[0].icon} size={35} />
                <span>{ formatTemp(temp.day) }</span>
            </div>
        </HoverTooltip>
    );
}


const Weather = ({ data }) => {
    return (
        <Card>
            <Card.Header>Weather</Card.Header>
            <div className="forecast-row">
                {data.daily.map((day, idx) => (
                    <WeatherDay day={day} idx={idx} key={idx} />
                ))}
            </div>

        </Card>
    )
};

Weather.propTypes = {
    data: PropTypes.object.isRequired,
};

export default Weather;
