import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Header from '../Text/Header'
import SummitClassificationBadge from '../SummitBadges/SummitClassificationBadge';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

import './LandingPanel.css';


const LandingPanel = ({ show, onClose }) => {

    const munro = <SummitClassificationBadge cls="Munro" margin={false} plural />;
    const corbett = <SummitClassificationBadge cls="Corbett" margin={false} plural />;
    const graham = <SummitClassificationBadge cls="Graham" margin={false} plural />;

    return (

        <Modal show={show}>
            <Modal.Body>

                <div className="landing-content">
                    <Header content="Welcome to Munro Bagging" />
                    <p>
                        The {munro} are a collection of the tallest mountains in Scotland 
                        offering a great experience to casual and experienced hikers.
                    </p>

                    <p>
                        "Bagging" a munro is the act of climbing to the summit.
                        Someone who has climbed all 282 is known as a
                        "<a href="https://www.smc.org.uk/hills/compleators">Compleator</a>".


                        {/* Compleators https://www.smc.org.uk/hills/compleators */}
                    </p>
                    
                    {/* <Header size="md" content="Other Mountains in Scotland" /> */}
                    <p>
                        There are plenty of mountains in Scotland without Munro status that still provide a challenge.
                        This map shows all {munro}, {corbett} and {graham}.
                    </p>

                    <Table className="mountain-classes" striped bordered>
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Height (feet)</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{munro}</td>
                                <td>Over 3000</td>
                                <td>282</td>
                            </tr>
                            <tr>
                                <td>{corbett}</td>
                                <td>2500-3000</td>
                                <td>222</td>
                            </tr>
                            <tr>
                                <td>{graham}</td>
                                <td>2000-2500</td>
                                <td>219</td>
                            </tr>
                        </tbody>
                    </Table>

                    <Button block variant="primary" onClick={onClose}>Find your next adventure</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

LandingPanel.defaultProps = {
    show: true,
    onClose: () => {},
};

LandingPanel.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
}

export default LandingPanel;
