import config from '../config';


const { host, summitsPath, walksPath, wikiPath, weather, travelTimePath } = config.walksApiConfig;


export const fetchSummits = async (options = {}) => {
    const url = new URL(host + summitsPath);
    if (options.location) {
        const { latitude, longitude } = options.location;
        url.searchParams.append('location', `${latitude},${longitude}`);
    }
    const response = await fetch(url.href, {
        method: 'GET',
        mode: 'same-origin',
    });
    return response.json();
;}


export const fetchWalks = async (options = {}) => {
    const url = new URL(host + walksPath);
    if (options.location) {
        const { latitude, longitude } = options.location;
        url.searchParams.append('location', `${latitude},${longitude}`);
    }
    if (options.classification) {
        url.searchParams.append('classification', options.classification.join(','));
    }
    const response = await fetch(url.href, {
        method: 'GET',
        mode: 'same-origin',
    });
    return response.json();
};


export const fetchWiki = async (summitId) => {
    const url = new URL(host + wikiPath + summitId);
    const response = await fetch(url.href, {
        method: 'GET',
        mode: 'same-origin',
    });
    if (response.status !== 200) {
        console.log(`${response.status} on ${url}`);
        return null;
    }
    return response.json();
};


const fetchWeather = async (summitId, path) => {
    const url = new URL(host + weather.basePath + summitId + path);
    const response = await fetch(url.href, {
        method: 'GET',
        mode: 'same-origin',
    });
    if (response.status !== 200) {
        console.log(`${response.status} on ${url}`);
        return null;
    }
    return response.json();
};
export const fetchCurrentWeather = async (summitId) => await fetchWeather(summitId, weather.currentPath);
export const fetchForecast = async (summitId) => await fetchWeather(summitId, weather.onecallPath);


export const fetchTravelTimes = async ({ latitude, longitude }, options = {}) => {
    if (!latitude || !longitude) return null;

    const url = new URL(host + travelTimePath + `${latitude},${longitude}`);

    if (options.classification) {
        url.searchParams.append('classification', options.classification.join(','));
    }

    const response = await fetch(url.href, {
        method: 'GET',
        mode: 'same-origin',
    });
    if (response.status !== 200) {
        console.log(`${response.status} on ${url}`);
        return null;
    }
    return response.json();
};
