import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { profileState } from '../../state/recoil';

import Profile from '../../profile';

const ProfileManager = props => {
    const [profile] = useRecoilState(profileState);

    useEffect(() => {
        Profile.saveProfile(profile);
    }, [profile]);

    return <></>;
};

export default ProfileManager;