import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner'

import SummitListItem from './SummitListItem';

import './SummitList.css'

const SummitList = ({
    loading,
    flush,
    className,
    summits,
    onSelectSummit,
    selected,
    walks,
    resultsPerPage,
    compact,
}) => {
    const [summitsToShow, setSummitsToShow] = useState(resultsPerPage);

    const handleShowMoreSummits = () => {
        setSummitsToShow(summitsToShow + resultsPerPage);
    };

    useEffect(() => {
        setSummitsToShow(resultsPerPage);
    }, [summits, resultsPerPage]);

    if (loading) {
        return <Spinner animation="border" />
    }

    return (
        <ListGroup variant={flush ? "flush" : ""} className={className}>
            {summits.slice(0, summitsToShow).map((summit) => (
                <ListGroup.Item
                    key={summit.id}
                    onClick={() => onSelectSummit(summit.id)}
                    action
                    active={selected === summit.id}
                >
                    <SummitListItem summit={summit} walks={walks} compact={compact} />
                </ListGroup.Item>
            ))}
            { summitsToShow < summits.length ?
                <ListGroup.Item
                    key="load-more"
                    className="load-more-list-item"
                >
                    <Button
                        onClick={() => handleShowMoreSummits()}
                        className="load-more-button"
                        block
                    >
                        Load More
                    </Button>
                </ListGroup.Item>
            : null}
        </ListGroup>
    );

}

SummitList.defaultProps = {
    loading: false,
    flush: false,
    className: "",
    summits: [],
    walks: {},
    onSelectSummit: () => {},
    resultsPerPage: 25,
    compact: false,
};

SummitList.propTypes = {
    loading: PropTypes.bool,
    flush: PropTypes.bool,
    className: PropTypes.string,
    summits: PropTypes.array,
    walks: PropTypes.object,
    onSelectSummit: PropTypes.func,
    resultsPerPage: PropTypes.number,
    compact: PropTypes.bool,
};

export default SummitList;