import { hasField, getField } from './sorting';
import { getWalksForSummit } from '../../utils'

const isDefined = value => value !== null && value !== undefined;

const inRange = (value, min, max) => min <= value && value <= max;

const walkHasField = (walks, fieldPath) => {
    return walks.some(walk => hasField(walk, fieldPath));
};

const getWalkField = (walks, fieldPath) => {
    return walks.map(walk => getField(walk, fieldPath)).filter(t => t !== null && !isNaN(t));
};

// const filters = [{
//     type: 'walk',
//     path: ['distance', 'km'],
//     max: 10,
// }, {
//     type: 'walk',
//     path: ['grade'],
//     min: 2,
//     max: 3,
// }];

export const filterSummits = (summits, allWalks, filters) => {
    if (!summits) return summits;

    return summits.slice().filter(summit => {
        const walks = getWalksForSummit(allWalks, summit);
        return filters.every(filter => {
            if (!isDefined(filter.min) && !isDefined(filter.max)) return true;
            
            const min = isDefined(filter.min) ? filter.min : -Infinity;
            const max = isDefined(filter.max) ? filter.max : Infinity;

            const { type, path } = filter;

            if (type === 'walk') {
                if (!walkHasField(walks, path)) return false;
                const fieldValue = getWalkField(walks, path);
                return fieldValue.some(value => inRange(value, min, max));
            } else {
                if (!hasField(summit, path)) return false;
                const fieldValue = getField(summit, path);
                return inRange(fieldValue, min, max);
            }
        });
    });
};

export const WALK_OBJECT_PATHS = {
    DISTANCE_KM: ['distance', 'km'],
    DRIVING_TIME: ['driving_time'],
    GRADE: ['grade'],
    BOG_FACTOR: ['bog_factor'],
    ASCENT: ['ascent'],
    WALKING_TIME: ['time', 0],
};

export const SUMMIT_OBJECT_PATHS = {
    ALTITUDE_METRES: ['altitude_metres'],
};
