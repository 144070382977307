import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';
import { withBreakpoints } from 'react-breakpoints';
import { IoCar } from 'react-icons/io5';

import MapMarker from './MapMarker';
import DrivingPointControl from './custom-controls/DrivingPointControl';
import HelpControl from './custom-controls/HelpControl';
import withProfile from '../../hocs/withProfile';

import config from '../../config';
import { getRouteNumbers } from '../../utils';
import { SummitColor } from '../../themes/default';
import { getWalksForSummit, zip } from '../../utils';

import './Map.css';


mapboxgl.accessToken = config.mapboxConfig.publicAccessToken;
const { privacyPolicyLink, cookiesPolicyLink, feedbackLink } = config.externalLinks;


const styles = {
    default: 'mapbox://styles/iain530/ckgxmjmce2alc19o68qiymu40',
    satellite: 'mapbox://styles/mapbox/satellite-v9',
};

const MAX_CLUSTER_ZOOM = 8;

class Map extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            map: null,
            mapLoadComplete: false,
        };
    }

    componentDidMount() {
        this.loadMap();
    }

    componentDidUpdate(prevProps, prevState) {
        const { focussed, summits, drivingStartPoint, profile } = this.props;

        if (focussed !== prevProps.focussed) {
            this.focusOn(focussed);
        }
        
        if (this.state.map) {
            if ((!prevState.mapLoadComplete && this.state.mapLoadComplete)
                || (summits !== prevProps.summits)
                || (profile !== prevProps.profile)) {
                this.setSummits();
            }   
        }

        if (drivingStartPoint !== prevProps.drivingStartPoint) {
            this.updateDrivingStartPointMarker();
        }
    }

    setSummits() {
        const summitsSource = this.state.map && this.state.map.getSource('summits');
        if (summitsSource) {
            summitsSource.setData({
                type: "FeatureCollection",
                features: this.mapSummitsToFeatures(this.props.summits),
            });
        }
    }

    mapSummitsToFeatures(summits) {
        const { isBagged, isFavourite } = this.props;


        return summits.map((summit) => {
            const { latitude, longitude } = summit.location
            return {
                "type": "Feature",
                "properties": {
                    id: summit.id,
                    classification: summit.classification[0],
                    bagged: isBagged(summit.id),
                    favourite: isFavourite(summit.id),
                },
                "geometry": {
                    "type": "Point",
                    "coordinates": [longitude, latitude],
                }
            };
        });
    }

    loadMap() {
        const map = new mapboxgl.Map({
            container: this.props.id,
            style: styles.default,
            zoom: 6.2,
            center: [-4.145673, 56.686408],
            maxPitch: 0,
            attributionControl: false,
        });

        this.setState({
            map,
        });

        map.addControl(
            new mapboxgl.AttributionControl({
                customAttribution: [
                    `<a title="Privacy" target="_blank" aria-label="Privacy" href="${privacyPolicyLink}">Privacy</a>`,
                    `<a title="Cookies" target="_blank" aria-label="Cookies" href="${cookiesPolicyLink}">Cookies</a>`,
                    `<a title="Send feedback" target="_blank" aria-label="Send feedback" href="${feedbackLink}">Send feedback</a>`,
                ]
            })
        );

        map.addControl(
            new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true,
                },
                trackUserLocation: true,
                fitBoundsOptions: {
                    maxZoom: 10,
                },
            }),
        );

        map.addControl(
            new mapboxgl.ScaleControl({}),
        );

        map.addControl(
            new mapboxgl.NavigationControl({}),
        );

        const features = this.mapSummitsToFeatures(this.props.summits)

        map.on('load', () => {
            // map.addSource('dem', {
            //     'type': 'raster-dem',
            //     'url': 'mapbox://mapbox.terrain-rgb'
            // });
            // map.addLayer(
            //     {
            //         'id': 'hillshading',
            //         'source': 'dem',
            //         'type': 'hillshade'
            //         // insert below waterway-river-canal-shadow;
            //         // where hillshading sits in the Mapbox Outdoors style
            //     },
            //     'waterway-shadow'
            // );


            map.addSource('summits', {
                type: 'geojson',
                data: {
                    "type": "FeatureCollection",
                    "features": features,
                },
                cluster: true,
                clusterMaxZoom: MAX_CLUSTER_ZOOM,
                clusterRadius: 30,
            });

            const gradient = `
            #cec492
            #ccc08f
            #c9bb8b
            #c7b788
            #c4b285
            #c2ae82
            #bfaa7e
            #bca57b
            #b9a178
            #b79d76
            #b49973
            #b19470
            #ae906d
            #ab8c6b
            #a88868
            #a58465
            #a18063
            #9e7c60
            #9b785e    
            `;
            const gradientColors = gradient.split('\n').map(s => s.trim()).filter(s => s.length > 0);       
            const splits = gradientColors.map((_, i) => i+2);

            map.addLayer({
                id: 'summits-cluster',
                type: 'circle',
                source: 'summits',
                filter: ['has', 'point_count'],
                paint: {
                    'circle-color': [
                        'step',
                        ['get', 'point_count'],
                        '#F1F075',
                        ...zip(splits, gradientColors),
                    ],
                    'circle-radius': [
                        'step',
                        ['get', 'point_count'],
                        15,
                        3, 16,
                        5, 17,
                        7, 18,
                        9, 19,
                        11, 20,
                    ],
                },
            });

            map.addLayer({
                id: 'summits-cluster-count',
                type: 'symbol',
                source: 'summits',
                filter: ['has', 'point_count'],
                layout: {
                    'text-field': '{point_count_abbreviated}',
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 14
                }
            });

            map.addLayer({
                id: 'summits-unclustered-point',
                type: 'circle',
                source: 'summits',
                filter: ['!', ['has', 'point_count']],
                paint: {
                    'circle-color': [
                        'case',
                        ['==', ['get', 'classification'], 'Munro'],
                        SummitColor.munro,
                        ['==', ['get', 'classification'], 'Corbett'],
                        SummitColor.corbett,
                        ['==', ['get', 'classification'], 'Graham'],
                        SummitColor.graham,
                        SummitColor.other,
                    ],
                    'circle-radius': 8,
                    'circle-stroke-width': 2,
                    'circle-stroke-color': '#fff',
                }
            });

            // Add favourite details
            map.addLayer({
                id: 'summits-favourite-icon',
                type: 'symbol',
                source: 'summits',
                filter: [
                    'all',
                    ['!', ['has', 'point_count']],
                    ['==', ['get', 'favourite'], true]
                ],
                layout: {
                    'icon-image': 'heart-white',
                    'icon-size': 0.55,
                    // 'icon-ignore-placement': true,
                },
                paint: {
                    'icon-translate': [0, .5],
                }
            });

            // Add bagged details
            map.addLayer({
                id: 'summits-bagged-icon',
                type: 'symbol',
                source: 'summits',
                filter: [
                    'all',
                    ['!', ['has', 'point_count']],
                    ['==', ['get', 'bagged'], true]
                ],
                layout: {
                    'icon-image': 'checkmark-white',
                    'icon-size': 0.6,
                    // 'icon-ignore-placement': true,
                },
                paint: {
                    'icon-translate': [0, .5],
                }
            });


            // inspect a cluster on click
            map.on('click', 'summits-cluster', (e) => {
                var features = map.queryRenderedFeatures(e.point, {
                    layers: ['summits-cluster']
                });

                map.easeTo({
                    padding: {
                        bottom: this.props.bottomOffset,
                    },
                    center: features[0].geometry.coordinates,
                    zoom: MAX_CLUSTER_ZOOM + 1,
                });
            });

            // Select a summit on click
            map.on('click', 'summits-unclustered-point', (e) => {
                const summitId = e.features[0].properties.id;
                console.log(summitId);

                if (this.props.onSelectSummit) this.props.onSelectSummit(summitId);
                this.focusOn(summitId);
            });

            // Style the cursor when hovering a cluster or point
            ['summits-cluster', 'summits-unclustered-point'].forEach((id) => {
                map.on('mouseenter', id, () => {
                    map.getCanvas().style.cursor = 'pointer';
                });
                map.on('mouseleave', id, () => {
                    map.getCanvas().style.cursor = '';
                });
            });

            // ResizeObserver not supported in some browsers: IE & FF Android
            // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver/ResizeObserver
            if (ResizeObserver) {
                this.resizeObserver = new ResizeObserver(() => this.onResize(0)).observe(this.mapEle);
            } else {
                window.addEventListener("resize", () => this.onResize(500));
                window.addEventListener("orientationchange", () => this.onResize(500));
            }

            if (this.props.focussed) {
                this.focusOn(this.props.focussed);
            }

            this.updateDrivingStartPointMarker();

            this.setState({
                mapLoadComplete: true,
            });
        });
    }

    onResize(delay = 0) {
        console.log("resizing")
        clearTimeout(this.onResizeTimeout);
        this.onResizeTimeout = setTimeout(() => this.state.map.resize(), delay);
    }

    getBounds(points) {
        const asArray = (point) => {
            if (!Array.isArray(point)) {
                return [point.lng, point.lat];
            }
            return point;
        }
        const pointsAsArray = points.map(asArray);

        let [minLng, minLat] = pointsAsArray[0];
        let [maxLng, maxLat] = pointsAsArray[0];

        pointsAsArray.forEach(([lng, lat]) => {
            if (lng < minLng) minLng = lng;
            else if (lng > maxLng) maxLng = lng;

            if (lat < minLat) minLat = lat;
            else if (lat > maxLat) maxLat = lat;
        });

        return [
            [minLng, minLat],
            [maxLng, maxLat],
        ];
    }

    focusOn(summitId) {
        const { map } = this.state;
        if (this.state.startPointMarkers) {
            this.state.startPointMarkers.forEach(marker => marker.remove());
        }
        if (this.state.marker) {
            this.state.marker.remove();
        }

        if (!summitId || !map) {
            return;
        }

        console.log(summitId);
        console.log(this.props.summits)

        const summit = this.props.summits.find(summit => summit.id === summitId);
        const { latitude, longitude } = summit.location
        const coords = [longitude, latitude];

        const div = document.createElement('div');
        ReactDOM.render(<MapMarker backgroundColor="#3fb1ce" />, div);
        const marker = new mapboxgl.Marker({
            element: div,
            anchor: 'bottom',
        }).setLngLat(coords).addTo(map);
        this.setState({
            marker,
        });
        
        const walks = getWalksForSummit(this.props.walks, summit);

        const routeNumbers = getRouteNumbers(walks);
        const startPointMarkers = walks.map((walk, i) => {
            const startCoords = [walk.start_point.longitude, walk.start_point.latitude];
            const startPointDiv = document.createElement('div');
            ReactDOM.render(<MapMarker content={ routeNumbers[i] } />, startPointDiv);
            return new mapboxgl.Marker({
                element: startPointDiv,
                anchor: 'bottom',
            }).setLngLat(startCoords).addTo(map);
        });

        this.setState({
            startPointMarkers,
        });

        if (startPointMarkers.length > 0) {
            const canvas = map.getCanvas();
            const verticalPadding = canvas.offsetHeight / 5;
            const horizontalPadding = canvas.offsetWidth / 5;
            
            map.fitBounds(this.getBounds([
                coords,
                ...startPointMarkers.map(m => m.getLngLat()),
            ]), {
                padding: {
                    top: verticalPadding,
                    bottom: verticalPadding + this.props.bottomOffset,
                    left: horizontalPadding,
                    right: horizontalPadding,
                },
            });
        } else {
            map.easeTo({
                padding: {
                    bottom: this.props.bottomOffset,
                },
                center: coords,
                zoom: Math.max(map.getZoom(), 11),
            });
        }

        document.getElementById(this.props.id).scrollIntoView();
    }

    updateDrivingStartPointMarker() {
        const { drivingStartMarker, map } = this.state;
        const { onDrivingStartPointChange, drivingStartPoint } = this.props;

        if (!map) {
            return;
        }

        const { latitude, longitude } = drivingStartPoint || { latitude: null, longitude: null };
        const lnglat = [longitude, latitude];


        if (drivingStartMarker) {
            drivingStartMarker.setLngLat(lnglat);
            if (latitude && longitude) {
                drivingStartMarker.addTo(map);
            }
        } else {
            const popup = new mapboxgl.Popup({
                offset: [0, -35],
                closeOnClick: true,
                closeOnMove: true,
                closeButton: false,
                className: 'driving-start-point-popup'
            }).setLngLat(lnglat).setText('Drag to set start point for driving times');

            const div = document.createElement('div');
            ReactDOM.render(
                <MapMarker
                    content={ <IoCar size="20px" /> }
                    backgroundColor="var(--info)"
                />, div);

            const marker = new mapboxgl.Marker({
                element: div,
                anchor: 'bottom',
                draggable: true,
            }).setLngLat(lnglat).setPopup(popup)
            
            if (latitude && longitude) {
                marker.addTo(map);
            }

            marker.on('dragstart', () => {
                map.getCanvas().style.cursor = 'grabbing';
            });
            marker.on('dragend', () => {
                map.getCanvas().style.cursor = '';
            });

            marker.on('dragend', () => {
                const { lng, lat } = marker.getLngLat();
                onDrivingStartPointChange({
                    latitude: lat,
                    longitude: lng,
                });
            });

            map.addControl(new DrivingPointControl({
                onSetDrivingPoint: (location) => {
                    marker.setLngLat(location);
                    
                    marker.addTo(map);
                    popup.addTo(map);

                    const { lng, lat } = location;
                    onDrivingStartPointChange({
                        latitude: lat,
                        longitude: lng,
                    });
                },
            }));

            map.addControl(new HelpControl());

            this.setState({
                drivingStartMarker: marker,
            });
        }
    }   

    render() {
        const { id, breakpoints, currentBreakpoint } = this.props;

        const classNames = ["map"]
        if (breakpoints[currentBreakpoint] <= breakpoints.tablet) {
            classNames.push("mobile")
        }

        return (
            <div id={id} ref={ele => this.mapEle = ele} className={classNames.join(" ")}></div>
        );
    }
}

Map.defaultProps = {
    bottomOffset: 0,
    loading: false,
    summits: [],
    walks: {},
    focussed: null,
    onSelectSummit: () => {},
    drivingStartPoint: null,
    onDrivingStartPointChange: () => {},
};

Map.propTypes = {
    id: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    summits: PropTypes.array,
    walks: PropTypes.object,
    bottomOffset: PropTypes.number,
    focussed: PropTypes.string,
    onSelectSummit: PropTypes.func,
    drivingStartPoint: PropTypes.object,
    onDrivingStartPointChange: PropTypes.func,
};

export default withProfile(withBreakpoints(Map));
