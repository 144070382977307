import { IoCheckmarkCircle, IoCloseCircle } from 'react-icons/io5';
import Button from 'react-bootstrap/Button';

import withProfile from '../../hocs/withProfile';


const BaggingButton = ({ summit, isBagged, bagSummit, unbagSummit, small, hideWhenBagged }) => {

    const bagged = isBagged(summit.id);

    const toggleBagged = (value) => {
        if (value) {
            bagSummit(summit.id);
        } else {
            unbagSummit(summit.id);
        }
    };


    return hideWhenBagged && bagged ? <></> : (
        <Button
            className="bag-button"
            variant={ bagged ? "outline-danger" : "outline-success" }
            onClick={() => toggleBagged(!bagged)}
            // block={ bagged ? false : true }
        >
            { bagged ? <IoCloseCircle size="24px" /> : <IoCheckmarkCircle size="24px" /> }
            { bagged ? `Unbag` : (small ? 'Bag' : `Bag ${summit.name}`) }
        </Button>
    );
};

BaggingButton.defaultProps = {
    small: false,
    hideWhenBagged: false,
};

export default withProfile(BaggingButton);