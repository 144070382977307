import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

import './ToggleButtons.css';


const ToggleButtons = ({ selected, onChange, buttonConfigs, multiple, name }) => {
    const [selectedState, setSelected] = useState(selected);

    const handleChange = (values) => {
        setSelected(values);
        onChange(values);
    };

    useEffect(() => {
        setSelected(selected);
    }, [selected]);

    return (
        <div className="toggle-buttons">
            <ToggleButtonGroup type={multiple ? "checkbox" : "radio"} value={selectedState} onChange={handleChange} name={name}>
                {buttonConfigs.map(({ value, label, variant }, idx) =>
                    <ToggleButton
                        key={idx}
                        className="toggle"
                        variant={variant ? variant : "fill-toggle"}
                        value={value !== null && value !== undefined ? value : label}
                    >
                        {label}
                    </ToggleButton>
                )}
            </ToggleButtonGroup>
        </div>
    );
};

ToggleButtons.defaultProps = {
    multiple: false,
    onChange: () => {},
};

ToggleButtons.propTypes = {
    name: PropTypes.string.isRequired,
    buttonConfigs: PropTypes.array.isRequired,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    selected: PropTypes.any,
};

export default ToggleButtons;