import React from 'react';
import PropTypes from 'prop-types';
import { withBreakpoints } from 'react-breakpoints';
import { withRouter } from 'react-router-dom';

import Button from 'react-bootstrap/Button';

import {
    IoSearchOutline,
    IoSearch,
    IoMapOutline,
    IoMap,
    IoHeartOutline,
    IoHeart,
    IoPersonCircleOutline,
    IoPersonCircle,
    IoSettingsOutline,
    IoSettings,
} from 'react-icons/io5';


import './Nav.css';

export const NAV_KEYS = {
    MAP: 'map',
    SEARCH: 'search',
    PROFILE: 'profile'
}

export const SUB_NAV_KEYS = {
    PROFILE: 'profile',
    FAV: 'favourites',
    BAGGED: 'bagged',
}


const navItems = [
    {
        key: NAV_KEYS.MAP,
        path: '/',
        defaultIcon: IoMapOutline,
        activeIcon: IoMap,
        tabName: 'Map'
    },
    {
        key: NAV_KEYS.SEARCH,
        path: '/search',
        defaultIcon: IoSearchOutline,
        activeIcon: IoSearch,
        tabName: 'Search'
    },
    // {
    //     key: NAV_KEYS.FAV,
    //     path: '/favourites',
    //     defaultIcon: IoHeartOutline,
    //     activeIcon: IoHeart,
    // },
    {
        key: NAV_KEYS.PROFILE,
        path: '/profile',
        defaultIcon: IoPersonCircleOutline,
        activeIcon: IoPersonCircle,
        tabName: 'Profile',
    },
    // {
    //     key: 'settings',
    //     path: '/settings',
    //     defaultIcon: IoSettingsOutline,
    //     activeIcon: IoSettings,
    //     tabName: 'Settings',
    // },
]


const NavIcon = ({ activeIcon, defaultIcon, active }) => {
    const Icon = active ? activeIcon : defaultIcon;

    return (
        <Icon
            className="nav-icon"
            size="28px"
        />
    )
};


const Nav = ({ activeKey, history, breakpoints, currentBreakpoint }) => {
    const desktopView = breakpoints[currentBreakpoint] > breakpoints.mobile;
    const classNames = desktopView ? ["nav", "desktop"] : ["nav"];

    return (
        <div className={classNames.join(" ")}>
            { navItems.map(({ defaultIcon, activeIcon, tabName, key, path }, idx) => 
                <Button
                    key={idx}
                    className={ key === activeKey ? "nav-item active" : "nav-item" }
                    variant="link"
                    onClick={() => history.push(path)}
                >
                    <NavIcon
                        active={key === activeKey}
                        activeIcon={activeIcon}
                        defaultIcon={defaultIcon}
                    />
                    <small>{ tabName }</small>
                </Button>
            )}
        </div>
    );
};

Nav.defaultProps = {
    activeKey: 'map',
};

Nav.propTypes = {
    activeKey: PropTypes.string,
}

export default withBreakpoints(withRouter(Nav));
