import React from 'react';
import PropTypes from 'prop-types';
import { Media } from 'react-breakpoints';

import BottomSheet from '../BottomSheet';
import SummitList from '../SummitList';
import { SearchControls } from '../Search';
import Panel from '../Panel';

import './SearchPanel.css';

class SearchPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            inputFocussed: false,
            query: '',
            sortSummitsOptions: null,
            decreasing: false,
            drivingTimeAvailable: this.props.walks && Object.values(this.props.walks).some(w => !!w.driving_time),
            filters: [],
        };

        this.openBottomSheet = this.openBottomSheet.bind(this);
        this.onTransitionEnd = this.onTransitionEnd.bind(this);
        this.onSearchInputChange = this.onSearchInputChange.bind(this);
        this.onSelectSummit = this.onSelectSummit.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.sortSummitsOptions !== prevState.sortSummitsOptions
            || this.state.decreasing !== prevState.decreasing
            || this.state.filters !== prevState.filters) {
            console.log("Sorting options changed");

            this.filterResults();
        }

        if (this.props.walks !== prevProps.walks) {
            this.setState({
                drivingTimeAvailable: Object.values(this.props.walks).some(w => !!w.driving_time),
            });
        }
    }

    componentDidMount() {
        this.filterResults();
    }
    
    openBottomSheet(open) {
        this.setState({
            open,
            inputFocussed: open ? this.state.inputFocussed : false,
        });
    }

    onTransitionEnd() {
        this.setState({
            inputFocussed: this.state.open,
        })
        window.scrollTo(0, 0);
    }

    onSearchInputChange(query) {
        this.setState({
            query,
        });
        this.filterResults();
    }

    filterResults() {
        clearTimeout(this.timeout);
        
        if (this.topOfListEle) {
            this.topOfListEle.scrollIntoView(false);
        }

        this.timeout = setTimeout(() => {
            this.props.onSearchChange({
                query: this.state.query,
                filters: this.state.filters,
                sortSummitsOptions: this.state.sortSummitsOptions,
                decreasing: this.state.decreasing,
            });
        }, 100);
    }

    onSelectSummit(summitId) {
        this.openBottomSheet(false);
        this.props.onSelectSummit(summitId);
    }

    onSortChange({ selected, decreasing, filters }) {
        this.setState({
            sortSummitsOptions: selected,
            decreasing,
            filters,
        });
    }

    render() {
        return (
            <Media>
                {({ breakpoints, currentBreakpoint }) => breakpoints[currentBreakpoint] > breakpoints.mobile ? (
                    <Panel
                        flush
                        stickyHeader={
                            <SearchControls
                                onSearchInputChange={this.onSearchInputChange}
                                drivingTimeAvailable={this.state.drivingTimeAvailable}
                                resultsCount={this.props.summits ? this.props.summits.length : 0}
                                onSortChange={this.onSortChange}
                                childNodes={this.props.children}
                            />
                        }
                        scrollTopButton
                        hidden={this.props.hidden}
                    >
                        <div ref={ele => this.topOfListEle = ele}></div>
                        { this.props.summits ?
                            <SummitList
                                className="results-list"
                                summits={this.props.summits}
                                walks={this.props.walks}
                                onSelectSummit={this.onSelectSummit}
                                flush
                            />
                        : null }
                    </Panel>
                ) : (
                    <BottomSheet
                        id="search"
                        fullScreen
                        shadowTip={false}
                        subHeader={SearchControls}
                        subHeaderProps={{
                            onSearchInputChange: this.onSearchInputChange,
                            searchInputFocussed: this.state.inputFocussed,
                            drivingTimeAvailable: this.state.drivingTimeAvailable,
                            sortingEnabled: this.state.open,
                            resultsCount: this.props.summits ? this.props.summits.length : 0,
                            onSortChange: this.onSortChange,
                            childNodes: this.props.children,
                        }}
                        onChange={this.openBottomSheet}
                        onTransitionEnd={this.onTransitionEnd}
                        scrollTopButton
                        hidden={this.props.hidden}
                    >
                        <div ref={ele => this.topOfListEle = ele}></div>
                        <div hide-on-bottom="true">
                            { this.props.summits ?
                                <SummitList
                                    className="results-list mobile"
                                    summits={this.props.summits.slice(0, 100)}
                                    walks={this.props.walks}
                                    onSelectSummit={this.onSelectSummit}
                                    flush
                                />
                            : null }
                        </div>
                    </BottomSheet>
                )}
            </Media>
        );
    }
}

SearchPanel.defaultProps = {
    summits: [],
    walks: {},
    onSelectSummit: () => {},
    hidden: false,
}

SearchPanel.propTypes = {
    summits: PropTypes.array,
    walks: PropTypes.object,
    children: PropTypes.node,
    onSelectSummit: PropTypes.func,
    hidden: PropTypes.bool,
}

export default SearchPanel;
