import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { IoWalkOutline, IoTimeOutline, IoTrendingUp, IoCar } from 'react-icons/io5';

import { createGoogleMapsUrl, formatDriveTime } from '../../utils';
import MapMarker from '../Map/MapMarker';
import SummitClassificationBadge from '../SummitBadges/SummitClassificationBadge';
import HoverTooltip from '../HoverTooltip';

import './WalkDetail.css';

const WalkDetail = ({ walk, routeNumber }) => (
    <>
        <Card.Header className="walk-detail-header">
            <MapMarker
                content={routeNumber}
                size={20}
                borderWidth={1}
                />
            Route on Walkhighlands
        </Card.Header>
            
        <Card.Body className="walk-detail">
            <Card.Title>{walk.name}</Card.Title>
            <div className="walk-stats">

                <HoverTooltip name="Distance">
                    <div className="stat">
                        <IoWalkOutline
                            size="2rem"
                        />
                        {walk.distance.km} km
                    </div>
                </HoverTooltip>

                <HoverTooltip name="Duration">
                    <div className="stat">
                        <IoTimeOutline
                            size="2rem"
                        />
                        { Array.isArray(walk.time)
                            ? <>{walk.time[0] / 60} - {walk.time[1] / 60} hours</>
                            : <>{walk.time / 60} hours</>
                        }    
                    </div>
                </HoverTooltip>

                <HoverTooltip name="Ascent">
                    <div className="stat">
                        <IoTrendingUp
                            size="2rem"
                        />
                        {walk.ascent} m
                    </div>
                </HoverTooltip>
            </div>

            <div className="progress-bar-stats">
                <div className="stat">
                    <small>Path grade</small>
                    <ProgressBar
                        variant={`grade-${walk.grade}`}
                        now={walk.grade * 20}
                        label={`${walk.grade}`}
                    />
                </div>
                <div className="stat">
                    <small>Bogginess</small>
                    <ProgressBar
                        variant={`bog-${walk.bog_factor}`}
                        now={walk.bog_factor * 20}
                        label={`${walk.bog_factor}`}
                    />
                </div>
            </div>

            { walk.summits.length > 1 ?
                <div className="walk-summits">
                    <div className="table-title">
                        <small>{ walk.summits.length } summits on this route</small>
                    </div>
                    <Table striped bordered size="sm" className="walk-summits-table">
                        <tbody>
                            {
                                walk.summits.map((summit, idx) => (
                                    <tr key={idx}>
                                        <td>{ summit.name }</td>
                                        <td>
                                            <div className="table-badge">
                                                <SummitClassificationBadge cls={summit.category} />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }       
                        </tbody>
                    </Table>
                </div>
            : null }

            <Button
                type="button"
                href={walk.url}
                target="_blank"
                block
                style={{ borderRadius: "var(--button-border-radius)" }}
            >
                View on Walkhighlands
            </Button>

            <Button
                type="button"
                variant="outline-secondary"
                href={createGoogleMapsUrl(walk.start_point)}
                target="_blank"
                block
                style={{ borderRadius: "var(--button-border-radius)" }}
            >
                <>
                    <IoCar size="24px" style={{marginRight: '.5rem'}}/>
                    { walk.driving_time ? <>
                        Drive to start point: {formatDriveTime(walk.driving_time)}
                    </> : <>
                        Directions to start point
                    </> }
                </>
            </Button>
        </Card.Body>
    </>
);

WalkDetail.defaultProps = {
    routeNumber: null,
};

WalkDetail.propTypes = {
    walk: PropTypes.object.isRequired,
    summit: PropTypes.object.isRequired,
    routeNumber: PropTypes.number,
};

export default WalkDetail;
