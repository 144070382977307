

const storage = window.localStorage;
const PROFILE_STORAGE_KEY = 'profile';
const PROFILE_BACKUP_STORAGE_KEY = 'profile_backup';

const STORAGE_SCHEMA_VERSION = 3;

const newProfile = now => ({
    bagged: {},
    favourites: {},
    dateCreated: now,
    seenLandingPage: false,
    schemaVersion: STORAGE_SCHEMA_VERSION,
});

const checkProfileVersion = profile => profile && profile.schemaVersion === STORAGE_SCHEMA_VERSION;

const upgradeProfileVersion = (profile) => {
    saveProfile(profile, { backup: true });
    if (profile.schemaVersion === 2) {
        Object.keys(profile.favourites).forEach((summitId) => {
            profile.favourites[summitId] = new Date();
        });
        profile.schemaVersion++;
    }
    return profile;
};


export const getProfile = () => {
    let profileJson = storage.getItem(PROFILE_STORAGE_KEY);
    let profile = profileJson && JSON.parse(profileJson);
    if (!profile) {
        console.log("No profile");
        profile = createProfile();
    } else if (!checkProfileVersion(profile)) {
        console.log("Profile version mismatch");
        profile = upgradeProfileVersion(profile);
        saveProfile(profile);
    } else {
        console.log("Profile loaded from local storage");
    }
    return profile;
};

export const saveProfile = (profile, { backup } = {}) => {
    console.log("Saving profile");
    storage.setItem(backup ? PROFILE_BACKUP_STORAGE_KEY : PROFILE_STORAGE_KEY, JSON.stringify({
        ...profile,
        lastUpdated: new Date(),
    }));
};

const createProfile = () => {
    console.log("Creating profile");
    const now = new Date();
    const profile = newProfile(now);
    saveProfile(profile);
    return profile;
};

const copyProfile = profile => JSON.parse(JSON.stringify(profile));

export const bagSummit = (oldProfile, id, date) => {
    const profile = copyProfile(oldProfile)
    profile.bagged[id] = date || new Date();
    return profile;
};

export const unbagSummit = (oldProfile, id) => {
    const profile = copyProfile(oldProfile);
    delete profile.bagged[id];
    return profile;
};

export const isBagged = (profile, id) => id in profile.bagged;

export const countBaggedByType = (profile, summits) => {
    const counts = {
        Munro: 0,
        Corbett: 0,
        Graham: 0,
    };
    if (!summits) return counts;

    const bagged = summits.filter(s => s.id in profile.bagged);
    bagged.forEach((summit) => {
        const type = summit.classification[0];
        counts[type]++;
    });
    return counts;
};

export const addFavouriteSummit = (oldProfile, id) => {
    const profile = copyProfile(oldProfile)
    if (!(id in profile.favourites)) {
        profile.favourites[id] = new Date();
    }
    return profile;
}

export const removeFavouriteSummit = (oldProfile, id) => {
    const profile = copyProfile(oldProfile);
    delete profile.favourites[id];
    return profile;
};

export const isFavourite = (profile, id) => id in profile.favourites;

const sortByDate = (profile, profileKey, summits) => summits.sort(
    (s1, s2) => new Date(profile[profileKey][s2.id]) - new Date(profile[profileKey][s1.id])
);

export const filterFavourites = (profile, summits) => {
    const favourites = (summits || []).filter(summit => isFavourite(profile, summit.id));
    return sortByDate(profile, 'favourites', favourites);
};

export const filterBagged = (profile, summits) => {
    const bagged = (summits || []).filter(summit => isBagged(profile, summit.id));
    return sortByDate(profile, 'bagged', bagged);
};

export const filterBaggedAndFavourites = (profile, summits) => {
    return (summits || []).filter(summit => isBagged(profile, summit.id) || isFavourite(profile, summit.id));
};

export const setSeenLandingPage = (oldProfile, seen) => {
    const profile = copyProfile(oldProfile);
    profile.seenLandingPage = seen;
    return profile;
};
