import React, {} from 'react';
import PropTypes from 'prop-types';
import { Media } from 'react-breakpoints';
import { withRouter } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { IoCheckmarkCircle, IoHeartCircle, IoArrowForward } from 'react-icons/io5';

import Panel from '../Panel';
import BottomSheet from '../BottomSheet';
import Header from '../Text/Header';
import BaggingCounts from './BaggingCounts';

import withProfile from '../../hocs/withProfile';

import config from '../../config';

import './Profile.css';
import SummitList from '../SummitList';


const Profile = ({ countBaggedByType, filterFavourites, filterBagged, onSelectSummit, summits, walks, hidden, history }) => {
    const baggedByType = countBaggedByType(summits);
    const favourites = filterFavourites(summits);
    const bagged = filterBagged(summits);

    const content = (
        <div className="profile-content">

            <div className="bagged-counts">
                <BaggingCounts
                    baggedByType={baggedByType}
                    type="Munro"
                    total={282}
                />
                <BaggingCounts
                    baggedByType={baggedByType}
                    type="Corbett"
                    total={222}
                />
                <BaggingCounts
                    baggedByType={baggedByType}
                    type="Graham"
                    total={219}
                />
            </div>

            <div hide-on-bottom="true">
                <Header
                    size="sm"
                    content={
                        <div style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                            <IoCheckmarkCircle className="checkmark" size="30px" style={{ marginRight: '0.125rem' }} /><span>Bagged ({bagged.length})</span>
                        </div>
                    }
                />
                <SummitList
                    loading={!summits}
                    summits={bagged.slice(0, 3)}
                    walks={walks}
                    onSelectSummit={onSelectSummit}
                />
                <div className="more-button">
                    { bagged.length > 0 &&
                        <Button block variant="outline-primary" onClick={() => history.push("/bagged")}>
                            Show All Bagged <IoArrowForward size="20px" />
                        </Button>
                    }
                </div>

                <Header
                    size="sm"
                    content={
                        <div style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                            <IoHeartCircle className="heart" size="30px" style={{ marginRight: '0.125rem' }} /><span>Favourites ({favourites.length})</span>
                        </div>
                    }
                />
                <SummitList
                    loading={!summits}
                    summits={favourites.slice(0, 3)}
                    walks={walks}
                    onSelectSummit={onSelectSummit}
                />
                <div className="more-button">
                    { favourites.length > 3 &&
                        <Button block variant="outline-primary" onClick={() => history.push("/favourites")}>
                            Show All Favourites <IoArrowForward size="20px" />
                        </Button>
                    }
                </div>
            </div>

            <Alert variant="info" hide-on-bottom="true">
                All profile data is stored per browser and cannot be transferred.
                If you would like to see more features added here (e.g. profile syncing) please <Alert.Link target="_blank" href={config.externalLinks.feedbackLink}>Give Feedback</Alert.Link>.
            </Alert>
        </div>
    );

    return (
        <Media>
            {({ breakpoints, currentBreakpoint }) => breakpoints[currentBreakpoint] > breakpoints.mobile ? (
                <Panel
                    stickyHeader={
                        <Header content="Profile" />
                    }
                    hidden={hidden}
                >
                    { content }
                </Panel>
            ) : (
                <BottomSheet
                    id="profile"
                    shadowTip={false}
                    title={<Header content="Profile" />}
                    fullScreen
                    hidden={hidden}
                    overflowHeight={252}
                    headerBorderOnBottom
                >
                    { content }
                </BottomSheet>
            )}
        </Media>
    );
};

Profile.defaultProps = {
    summits: [],
    walks: {},
    onDismiss: () => {},
    hidden: false,
}

Profile.propTypes = {
    countBaggedByType: PropTypes.func.isRequired,
    summits: PropTypes.array,
    walks: PropTypes.object,
    onDismiss: PropTypes.func,
    hidden: PropTypes.bool,
}

export default withRouter(withProfile(Profile));
