import { getWalksForSummit } from '../../utils';

const compare = compareValues => (getField, descending) => (s1, s2) => {
    const f1 = getField(s1);
    const f2 = getField(s2);
    if (f1 === f2) return 0;

    if (f1 === null) {
        return 1;
    } else if (f2 === null) {
        return -1;
    } else if (descending) {
        return -compareValues(f1, f2);
    }
    return compareValues(f1, f2);
};

const compareNumber = compare((f1, f2) => f1 - f2);
const compareString = compare((f1, f2) => f1.localeCompare(f2));

export const hasField = (obj, fieldPath) => {
    let currentLevel = obj;
    for (let i = 0; i < fieldPath.length; i++) {
        if (!(currentLevel[fieldPath[i]]))
            return false;
        currentLevel = currentLevel[fieldPath[i]];
    }
    return true;
}

export const getField = (obj, fieldPath) => {
    if (!hasField(obj, fieldPath)) {
        return null;
    }

    let currentLevel = obj;
    for (let i = 0; i < fieldPath.length; i++) {
        currentLevel = currentLevel[fieldPath[i]];
    }
    return currentLevel;
}

const makeGetWalkField = (allWalks, fieldPath) => summit => {
    const walks = getWalksForSummit(allWalks, summit);
    const fieldValues = walks.map(walk => getField(walk, fieldPath)).filter(t => t !== null && !isNaN(t));
    if (fieldValues.length === 0) return null;
    return Math.min(...fieldValues); 
};


const makeSortSummitsByWalkField = (fieldPath, compare) => (summits, allWalks, decreasing) => {
    if (!summits) return summits;

    const getField = makeGetWalkField(allWalks, fieldPath);

    const summitsCopy = summits.slice()
    summitsCopy.sort(compare(getField, decreasing));

    return summitsCopy;
};

const makeSortSummitsBySummitField = (fieldPath, compare) => (summits, _, decreasing) => {
    if (!summits) return summits;

    const getSummitField = s => getField(s, fieldPath);

    const summitsCopy = summits.slice();
    summitsCopy.sort(compare(getSummitField, decreasing));

    return summitsCopy;
};

// Walk attributes
export const byDrivingTime = makeSortSummitsByWalkField(['driving_time'], compareNumber);
export const byDistance = makeSortSummitsByWalkField(['distance', 'km'], compareNumber);
export const byGrade = makeSortSummitsByWalkField(['grade'], compareNumber);
export const byBogFactor = makeSortSummitsByWalkField(['bog_factor'], compareNumber);
export const byAscent = makeSortSummitsByWalkField(['ascent'], compareNumber);
export const byWalkingTime = makeSortSummitsByWalkField(['time', 0], compareNumber)

// Summit attributes
export const byHeight = makeSortSummitsBySummitField(['altitude_metres'], compareNumber);
export const byAlphabetical = makeSortSummitsBySummitField(['name'], compareString);
