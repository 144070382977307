import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Range, createSliderWithTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';

import './FilterSlider.css';

const RangeWithTooltip = createSliderWithTooltip(Range);

const FilterSlider = ({ onAfterChange, markCurrentValue, formatLabel, filterConfig, ...rangeProps }) => {
    const { min, max, type, path } = filterConfig;
    const defaultValue = [min, max];

    const [rangeValue, setRangeValue] = useState(defaultValue);

    const marks = {
        ...rangeProps.marks,
        ...(markCurrentValue ? {
            [rangeValue[0]]: { label: formatLabel(rangeValue[0]) },
            [rangeValue[1]]: { label: formatLabel(rangeValue[1]) },
        } : {}),
    };

    return (
        <div className="filter-slider-container">
            <RangeWithTooltip
                {...rangeProps}
                min={min}
                max={max}
                defaultValue={defaultValue}
                marks={marks}
                className="filter-slider"
                onAfterChange={(range) => {
                    const minValue = range[0] !== min ? range[0] : null;
                    const maxValue = range[1] !== max ? range[1] : null;  
                    onAfterChange({
                        type,
                        path,
                        min: minValue,
                        max: maxValue,
                    });
                }}
                onChange={range => setRangeValue(range)}
                tipFormatter={formatLabel}
            />
        </div>
    );
};

FilterSlider.defaultProps = {
    onAfterChange: () => {},
    marks: {},
    formatLabel: value => value.toString(),
};

FilterSlider.propTypes = {
    onAfterChange: PropTypes.func,
    marks: PropTypes.object,
    formatLabel: PropTypes.func,
};

export default FilterSlider;
