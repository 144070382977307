import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Media } from 'react-breakpoints';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import { IoSadOutline, IoArrowBackCircleOutline } from 'react-icons/io5';

import FavouriteButton from './FavouriteButton';
import BaggingButton from '../Bagging/BaggingButton';
import BottomSheet from '../BottomSheet';
import WalkDetail from '../WalkDetail';
import SummitBadges from  '../SummitBadges';
import SummitName from '../SummitName';
import Bagging from '../Bagging';
import Panel from '../Panel';
import WikiSummary from '../WikiSummary';
import Weather from '../Weather';

import { getRouteNumbers } from '../../utils';
import { fetchWiki, fetchForecast } from '../../services/walksApi';

import './SummitBottomSheet.css';
import ShareButton from './ShareButton';


const SummitBottomSheet = props => {
    const { walks, summit, onDismiss, hidden } = props;
    const [wikiLoading, setWikiLoading] = useState(true);
    const [wikiData, setWikiData] = useState(null);
    const [weatherLoading, setWeatherLoading] = useState(true);
    const [weatherData, setWeatherData] = useState(null);

    useEffect(() => {
        setWikiLoading(true);
        setWikiData(null);
        if (summit.wikipedia) {
            const fetchData = async () => {
                const data = await fetchWiki(summit.id);
                if (data) {
                    setWikiData(data);
                }
                setWikiLoading(false);
            };
            fetchData();
        } else {
            setWikiLoading(false);
        }
    }, [summit.id, summit.wikipedia]);

    useEffect(() => {
        setWeatherLoading(true);
        setWeatherData(null);
        const fetchData = async () => {
            const data = await fetchForecast(summit.id);
            console.log(data);
            if (data) {
                setWeatherData(data);
            }
            setWeatherLoading(false);
        };

        fetchData();
    }, [summit.id]);

    const routeNumbers = getRouteNumbers(walks);

    const routeDetails = walks.length > 0 ? (
        <>
            {
                walks.map((walk, idx) => (
                    <Card key={idx}>
                        <WalkDetail
                            idx={idx}
                            walk={walk}
                            summit={summit}
                            routeNumber={ routeNumbers[idx] }
                        />
                    </Card>
                ))
            }
        </>
    ) : (
        <div className="no-routes">
            <IoSadOutline className="no-routes-icon" />
            No routes for this summit yet
        </div>
    );

    const body = weatherLoading || wikiLoading ? (
        <div
            hide-on-bottom="true"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Spinner animation="border" />
        </div>
    ) : (
        <div hide-on-bottom="true">
            <div className="quick-actions">
                <FavouriteButton summitId={summit.id} />
                <BaggingButton summit={summit} small hideWhenBagged />
                <ShareButton summit={summit} />
            </div>
            { wikiData ? <WikiSummary data={wikiData} /> : null}
            { routeDetails }
            { weatherData ? <Weather data={weatherData} /> : null }
            <Bagging summit={summit} />
        </div>
    );
        

    return (
        <>
            <Helmet>
                <title>{`${ summit.name } | ${ summit.classification } Bagging`}</title>
                <meta
                    name="description"
                    content={`${ summit.altitude_metres }m | ${ summit.classification }`}
                />
            </Helmet>
            <Media>
                {({ breakpoints, currentBreakpoint }) => breakpoints[currentBreakpoint] > breakpoints.mobile ? (
                    <Panel
                        stickyHeader={
                            <>
                                <Button
                                    variant="link"
                                    onClick={onDismiss}
                                    className="back-to-search-button"
                                >
                                    <IoArrowBackCircleOutline size="28px" style={{marginRight: ".25rem"}}/> Back
                                </Button>
                                <SummitName size="lg" summit={summit} />
                                <SummitBadges summit={summit} walks={walks} />
                            </>
                        }
                        hidden={hidden}
                    >
                        { body }
                    </Panel>
                ) : (
                    <BottomSheet
                        id={`summit-${summit.id}`}
                        title={<SummitName size="lg" summit={summit} />}
                        onDismiss={onDismiss}
                        subHeader={SummitBadges}
                        subHeaderProps={{
                            summit,
                            walks,
                        }}
                        clickBottomOpen
                        fullScreen
                        hidden={hidden}
                    >
                        { body }
                    </BottomSheet>
                )}
            </Media>
        </>
    );
}

SummitBottomSheet.defaultProps = {
    hidden: false,
};

SummitBottomSheet.propTypes = {
    summit: PropTypes.object.isRequired,
    walks: PropTypes.array.isRequired,
    onDismiss: PropTypes.func.isRequired,
    hidden: PropTypes.bool,
};

export default SummitBottomSheet;
