import React from 'react';
import PropTypes from 'prop-types';
import ToggleButtons from '../ToggleButtons';

import './SummitClassificationFilters.css';


class SummitClassificationFilters extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: this.props.allChecked ? [] : this.props.default,
            allChecked: this.props.allChecked,
        };

        this.handleSummitsChange = this.handleSummitsChange.bind(this);
        this.handleAllChange = this.handleAllChange.bind(this);
    }

    handleSummitsChange(value) {
        // When zero filters checked, selected all summits
        if (value.length === 0) {
            this.setState({
                checked: [],
                allChecked: true,
            });
            this.props.onChange(true);
        } else {
            this.setState({
                checked: value,
                allChecked: false,
            });
            this.props.onChange(value);
        }
    }

    handleAllChange() {
        this.setState({
            allChecked: true,
            checked: [],
        });
        this.props.onChange(true);
    }
    
    render() {
        const buttonConfigs = [{
            value: "Munro",
            label: "Munros",
            variant: "munro",
        }, {
            value: "Corbett",
            label: "Corbetts",
            variant: "corbett",
        }, {
            label: "Grahams",
            value: "Graham",
            variant: "graham",
        }];

        return (
            <div className="summit-filters">
                <ToggleButtons
                    name="summit-all-toggle"
                    multiple
                    selected={this.state.allChecked ? ["All"] : []}
                    onChange={this.handleAllChange}
                    buttonConfigs={[{
                        label: "All",
                    }]}
                />
                <ToggleButtons
                    name="summit-filter-toggles"
                    multiple
                    selected={this.state.checked}
                    buttonConfigs={buttonConfigs}
                    onChange={this.handleSummitsChange}
                />
            </div>
        );
    }
}

SummitClassificationFilters.defaultProps = {
    onChange: () => {},
    default: [],
    allChecked: true,
};

SummitClassificationFilters.propTypes = {
    onChange: PropTypes.func,
    default: PropTypes.array,
    allChecked: PropTypes.bool,
};

export default SummitClassificationFilters;
