import React from 'react';

import Button from 'react-bootstrap/Button';
import { IoArrowUp } from 'react-icons/io5';

import './ScrollTopButton.css';

class ScrollTopButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showScrollButton: false,
        };
    }

    componentDidMount() {
        if (this.props.scrollContainerRef.current) {
            this.props.scrollContainerRef.current.addEventListener("scroll", this.handleScroll)
        }
    }

    componentWillUnmount() {
        if (this.props.scrollContainerRef.current) {
            this.props.scrollContainerRef.current.removeEventListener("scroll", this.handleScroll)
        }
    }

    handleScroll = () => {
        const { showScrollButton } = this.state;
        const { scrollContainerRef } = this.props;
        
        const current = scrollContainerRef.current.scrollTop;
        const total = scrollContainerRef.current.scrollHeight;
        
        const overThreshold = current * 4 > total;

        if (overThreshold !== showScrollButton) {
            this.setState({
                showScrollButton: overThreshold,
            });
        }
    }

    handleScrollToTopClick = () => {
        this.props.scrollContainerRef.current.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    render() {
        return(
            <div className="scroll-top-button-container">
                <Button
                    className={this.state.showScrollButton ? "scroll-top-button" : "scroll-top-button hidden"}
                    onClick={this.handleScrollToTopClick}
                    variant="primary"
                >
                    <IoArrowUp size="20px" />
                </Button>
            </div>
        );
    }
}

export default ScrollTopButton;
