import ReactDOM from 'react-dom';


import './CustomControl.css';


class CustomControl {
    onAdd(map) {
        this._map = map;
        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
        ReactDOM.render(this.render(), this._container);
        document.addEventListener("dragover", e => e.preventDefault());
        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }

    getDefaultPosition = () => "top-right";
}

export default CustomControl;