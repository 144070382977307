import React from 'react';
import PropTypes from 'prop-types';

import Header from '../Text/Header';
import withProfile from '../../hocs/withProfile';

import { IoCheckmarkCircle, IoHeartCircle } from 'react-icons/io5';

import './SummitName.css';


const SummitName = ({ summit, size, isBagged, isFavourite }) => {
    const bagged = isBagged(summit.id);
    const favourite = isFavourite(summit.id);

    return (
        <div>
            <div className="summit-name">
                <Header size={size} content={summit.name} />
                { bagged ?
                    <IoCheckmarkCircle className="checkmark" size="30px" />   
                : null}
                { favourite ?
                    <IoHeartCircle className="heart" size="30px" />
                : null}
            </div>
            { summit.alternative_names.length > 0 ?
                <div className="alternative-names">
                    { summit.alternative_names.join(' • ') }
                </div>
            : null}
        </div>
    );
};

SummitName.propTypes = {
    summit: PropTypes.object.isRequired,
    size: PropTypes.oneOf(["sm", "lg"]),
};

SummitName.defaultProps = {
    size: "sm",
};

export default withProfile(SummitName);