import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import SummitList from '../SummitList';
import ResponsivePanel from '../ResponsivePanel';
import Header from '../Text/Header';

import withProfile from '../../hocs/withProfile';

import './BaggedSummits.css';


const BaggedSummits = ({ filterBagged, history, summits, walks, onSelectSummit, hidden }) => {
    const bagged = filterBagged(summits);

    return (
        <ResponsivePanel
            panelProps={{
                stickyHeader: <Header content="Bagged" />,
                hidden,
                flush: true,
            }}
            bottomSheetProps={{
                id: 'bagged',
                title: <Header content="Bagged" />,
                fullScreen: true,
                initialBreak: 'top',
                hidden,
                overflowHeight: 137,
                onDismiss: () => history.push('/profile'),
            }}
        >
            <div hide-on-bottom="true" className="bagged-panel">
                <SummitList
                    loading={!summits}
                    summits={bagged}
                    walks={walks}
                    onSelectSummit={onSelectSummit}
                    flush
                />
            </div>
        </ResponsivePanel>
    );
};

BaggedSummits.defaultProps = {
    summits: [],
    walks: {},
    onSelectSummit: () => {},
    hidden: false,
};

BaggedSummits.propTypes = {
    filterBagged: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    summits: PropTypes.array,
    walks: PropTypes.object,
    onSelectSummit: PropTypes.func,
    hidden: PropTypes.bool,
};

export default withRouter(withProfile(BaggedSummits));
