import React from 'react';
import PropTypes from 'prop-types';

import Badge from 'react-bootstrap/Badge';
import { IoWalk, IoCar } from 'react-icons/io5';
import { FaMountain } from 'react-icons/fa';

import SummitClassificationBadge from './SummitClassificationBadge';

import { formatDriveTime } from '../../utils';

import './SummitBadges.css';


const SummitBadges = ({ walks, summit }) => {
    const { classification, altitude_metres } = summit;

    // TODO: multiple walk distances?
    // TODO: least driving time?

    return (
        <div className="badge-row">
            {
                classification.map((cls, idx) => <SummitClassificationBadge key={idx} cls={cls} />)
            }
            <Badge pill className="summit-badge" variant="secondary"><FaMountain /> { Math.round(altitude_metres) } m</Badge>

            { walks.length > 0 ? (<>
                <Badge pill className="summit-badge" variant="info"><IoWalk style={{ marginRight: 0 }} /> { walks[0].distance.km } km</Badge>
                { walks[0].driving_time ?
                    <Badge pill className="summit-badge" variant="info"><IoCar /> { formatDriveTime(walks[0].driving_time) }</Badge>
                    : null
                }
            </>) : null }
        </div>
    );
};

SummitBadges.defaultProps = {
    walks: [],
};

SummitBadges.propTypes = {
    summit: PropTypes.object.isRequired,
    walks: PropTypes.array,
};

export default SummitBadges;
