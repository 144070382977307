import React from 'react';
import PropTypes from 'prop-types';
import SummitBadges from '../SummitBadges';
import SummitName from '../SummitName';

import { getWalksForSummit } from '../../utils';


const SummitListItem = ({ summit, walks, compact }) => {
    const walksForSummit = getWalksForSummit(walks, summit);

    return (
        <div className="summit-list-item">
            <SummitName summit={summit} />
            { !compact && <SummitBadges summit={summit} walks={walksForSummit} /> }
        </div>
    );
}

SummitListItem.defaultProps = {
    walks: {},
    compact: false,
};

SummitListItem.propTypes = {
    summit: PropTypes.object.isRequired,
    walks: PropTypes.object,
    compact: PropTypes.bool,
};

export default SummitListItem;