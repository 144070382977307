import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; 
import ReactBreakpoints from 'react-breakpoints';
import { RecoilRoot } from 'recoil';
import './index.css';
import App from './App';
import { NAV_KEYS, SUB_NAV_KEYS } from './components/Nav';
import ProfileManager from './components/ProfileManager';
import reportWebVitals from './reportWebVitals';

const breakpoints = {
  mobile: 320,
  mobileLandscape: 550,
  tablet: 768,
  tabletLandscape: 1024,
  desktop: 1200,
  desktopLarge: 1500,
  desktopWide: 1920,
};

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <Router>
        <ReactBreakpoints breakpoints={breakpoints}>
          <Switch>
            <Route
              path="/"
              children={<App navKey={NAV_KEYS.MAP} />}
              exact
            />
            <Route
              path="/search"
              children={<App navKey={NAV_KEYS.SEARCH} />}
              exact
            />
            <Route
              path="/profile"
              children={<App navKey={NAV_KEYS.PROFILE} subNavKey={SUB_NAV_KEYS.PROFILE} />}
              exact
            />
            <Route
              path="/bagged"
              children={<App navKey={NAV_KEYS.PROFILE} subNavKey={SUB_NAV_KEYS.BAGGED} />}
              exact
            />
            <Route
              path="/favourites"
              children={<App navKey={NAV_KEYS.PROFILE} subNavKey={SUB_NAV_KEYS.FAV} />}
              exact
            />
            <Route
              path="/:summitId?"
              children={<App navKey={NAV_KEYS.SEARCH} />}
            />
          </Switch>
        </ReactBreakpoints>
        <ProfileManager />
      </Router>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
