import React from 'react';
import PropTypes from 'prop-types';
import {
    // Day
    WiDaySunny,
    WiDayCloudy,
    WiCloud,
    WiCloudy,
    WiRain,
    WiDayRain,
    WiThunderstorm,
    WiSnow,
    WiDayFog,
    // Night
    WiNightClear,
    WiNightAltCloudy,
    WiNightAltRain,
    WiNightFog,
    // Overrides
    WiDayLightning,
    WiNightAltLightning,
    WiVolcano,
    WiTornado,
} from 'weather-icons-react';


const openWeatherIconsMapping = {
    '01d': WiDaySunny,
    '02d': WiDayCloudy,
    '03d': WiCloud,
    '04d': WiCloudy,
    '09d': WiRain,
    '10d': WiDayRain,
    '11d': WiThunderstorm,
    '13d': WiSnow,
    '50d': WiDayFog,

    '01n': WiNightClear,
    '02n': WiNightAltCloudy,
    '03n': WiCloud,
    '04n': WiCloudy,
    '09n': WiRain,
    '10n': WiNightAltRain,
    '11n': WiThunderstorm,
    '13n': WiSnow,
    '50n': WiNightFog,
};

// Override icon for specific weather ids
const openWeatherIdsMapping = {
    day: {
        210: WiDayLightning,
        211: WiDayLightning,
        212: WiDayLightning,
        221: WiDayLightning,

        762: WiVolcano,
        781: WiTornado,
    },
    night: {
        210: WiNightAltLightning,
        211: WiNightAltLightning,
        212: WiNightAltLightning,
        221: WiNightAltLightning,

        762: WiVolcano,
        781: WiTornado,
    },
};

const WeatherIcon = ({id, icon, size}) => {
    const timeOfDay = icon.endsWith('n') ? 'night' : 'day';
    const Icon = openWeatherIdsMapping[timeOfDay][id] || openWeatherIconsMapping[icon];
    return <Icon size={size} />
}

WeatherIcon.defaultProps = {
    size: 30,
};

WeatherIcon.propTypes = {
    id: PropTypes.number.isRequired,
    icon: PropTypes.string.isRequired,
    size: PropTypes.number,
};

export default WeatherIcon;
