import React from 'react';
import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const createRenderTooltip = (name, id) => {
    if (!id) {
        id = name;
    }

    return (props) => (
        <Tooltip id={`tooltip-${id}`} {...props}>
            { name }
        </Tooltip>
    );
};

const HoverTooltip = ({ name, id, children, placement }) => {
    return (
        <OverlayTrigger
            placement={placement}
            delay={{ show: 0, hide: 0 }}
            overlay={createRenderTooltip(name, id)}
        >
            { children }
        </OverlayTrigger>
    );
};

HoverTooltip.defaultProps = {
    id: null,
    placement: "top",
};

HoverTooltip.propTypes = {
    children: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    placement: PropTypes.string,
};

export default HoverTooltip;
