import React from 'react';
import PropTypes from 'prop-types';
import { FaMountain } from 'react-icons/fa'
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form'

import { toISODateString, addDays, now } from '../../utils';
import withProfile from '../../hocs/withProfile';

import './Bagging.css';
import BaggingButton from './BaggingButton';

const Bagging = ({
    summit,
    profile,
    bagSummit,
    isBagged,
}) => {

    const bagged = isBagged(summit.id);

    const onBaggedDateChange = (newValue) => {
        if (newValue) {
            bagSummit(summit.id, new Date(newValue));
        }
    }

    const renderBaggingText = bagged => {
        if (bagged) {
        const bagDate = new Date(profile.bagged[summit.id]);
            return (
                <>
                    You bagged {summit.name} on 
                    <Form.Control
                        as="input"
                        type="date"
                        className="bagged-date-picker"
                        value={toISODateString(bagDate)}
                        max={toISODateString(now())}
                        onChange={(e) => onBaggedDateChange(e.target.value)}
                    />
                </>
            );
        }
        return `You have not climbed ${summit.name}`;
    };

    return (
        <>
            <Card className="bagging-card">
                <Card.Header>Bagging</Card.Header>
                <Card.Body className="bagging-section">
                    <FaMountain size="2.5rem" color="var(--secondary)" />
                    <Card.Text className="text-center">
                        { renderBaggingText(bagged) }
                    </Card.Text>
                    <BaggingButton summit={summit} />
                </Card.Body>
            </Card>
        </>
    );
};

Bagging.propTypes = {
    summit: PropTypes.object.isRequired,
    profile: PropTypes.object,
    bagSummit: PropTypes.func,
    unbagSummit: PropTypes.func,
};

export default withProfile(Bagging);
