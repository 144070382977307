import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import FilterSlider from './FilterSlider';

import { WALK_OBJECT_PATHS, SUMMIT_OBJECT_PATHS } from './filters';


const filterConfigs = {
    drivingTime: {
        min: 30,
        max: 180,
        path: WALK_OBJECT_PATHS.DRIVING_TIME,
        type: 'walk',
    },
    summitHeight: {
        min: 600,
        max: 1350,
        path: SUMMIT_OBJECT_PATHS.ALTITUDE_METRES,
        type: 'summit',
    },
    walkDistance: {
        min: 5,
        max: 25,
        path: WALK_OBJECT_PATHS.DISTANCE_KM,
        type: 'walk',
    },
    walkAscent: {
        min: 250,
        max: 1500,
        path: WALK_OBJECT_PATHS.ASCENT,
        type: 'walk',
    },
    walkTime: {
        min: 60,
        max: 600,
        path: WALK_OBJECT_PATHS.WALKING_TIME,
        type: 'walk',
    },
    grade: {
        min: 1,
        max: 5,
        path: WALK_OBJECT_PATHS.GRADE,
        type: 'walk',
    },
    bogginess: {
        min: 1,
        max: 5,
        path: WALK_OBJECT_PATHS.BOG_FACTOR,
        type: 'walk',
    }
};

const getFilterStateKey = (type, path) => `${type}.${path.join('.')}`;

const FilterControls = ({ drivingTimeAvailable, onChange, disabled }) => {
    const [activeFilters, setActiveFilters] = useState({});

    const onAfterChange = useCallback(({ type, path, min, max }) => {
        const filters = {...activeFilters};
        const stateKey = getFilterStateKey(type, path);
        if (stateKey in filters && min === null && max === null) {
            delete filters[stateKey];
        } else {
            filters[stateKey] = {
                type,
                path,
                min,
                max,
            };
        }
        setActiveFilters(filters);
        onChange(Object.values(filters));
    }, [activeFilters, setActiveFilters, onChange]);

    return (
        <div className="filter-controls">
            { drivingTimeAvailable ?
                <>
                    <strong>Driving Time to Start</strong>
                    <FilterSlider
                        filterConfig={filterConfigs.drivingTime}
                        step={30}
                        formatLabel={(value) => {
                            const formatted = (value / 60);
                            if (value === 30) return `< ${formatted}hr`;
                            if (value === 180) return `${formatted}hr+`;
                            return `${formatted} hr`;
                        }}
                        pushable={0}
                        markCurrentValue
                        onAfterChange={onAfterChange}
                        disabled={disabled}
                    />
                </>
            : null }
            <strong>Summit Height</strong>
            <FilterSlider
                filterConfig={filterConfigs.summitHeight}
                step={25}
                formatLabel={(value) => {
                    if (value === 1350) return `1345m`;
                    if (value === 600) return `< ${value}m`;
                    return `${value}m`;
                }}
                pushable={100}
                markCurrentValue
                onAfterChange={onAfterChange}
                disabled={disabled}
            />
            <strong>Walk Distance</strong>
            <FilterSlider
                filterConfig={filterConfigs.walkDistance}
                step={1}
                defaultValue={[5, 25]}
                formatLabel={(value) => {
                    if (value === 25) return `${value}km+`;
                    if (value === 5) return `< ${value}km`;
                    return `${value}km`;
                }}
                pushable={2}
                markCurrentValue
                onAfterChange={onAfterChange}
                disabled={disabled}
            />
            <strong>Walk Ascent</strong>
            <FilterSlider
                filterConfig={filterConfigs.walkAscent}
                step={25}
                formatLabel={(value) => {
                    if (value === 1500) return `${value}m+`;
                    if (value === 250) return `< ${value}m`;
                    return `${value}m`;
                }}
                pushable={200}
                markCurrentValue
                onAfterChange={onAfterChange}
                disabled={disabled}
            />
            <strong>Walk Time</strong>
            <FilterSlider
                filterConfig={filterConfigs.walkTime}
                step={30}
                formatLabel={(value) => {
                    const formatted = (value / 60).toString();
                    if (value === 600) return `${formatted}hr+`;
                    if (value === 60) return `< ${formatted}hr`;
                    return `${formatted}hr`;
                }}
                pushable={0}
                markCurrentValue
                onAfterChange={onAfterChange}
                disabled={disabled}
            />
            {/* <strong>Grade</strong>
            <FilterSlider
                filterConfig={filterConfigs.grade}
                onAfterChange={onAfterChange}
                pushable={0}
                markCurrentValue
            />
            <strong>Bogginess</strong>
            <FilterSlider
                filterConfig={filterConfigs.bogginess}
                onAfterChange={onAfterChange}
                pushable={0}
                markCurrentValue
            /> */}
        </div>
    );
};

FilterControls.defaultProps = {
    drivingTimeAvailable: false,
    disabled: false,
    onChange: () => {},
};

FilterControls.propTypes = {
    drivingTimeAvailable: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

export default FilterControls;
