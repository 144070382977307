import React from 'react';
import { IoCar } from 'react-icons/io5';
import CustomControl from './CustomControl';

class DrivingPointControl extends CustomControl {
    constructor(options = {}) {
        const { onSetDrivingPoint } = options;
        super();
        this.onSetDrivingPoint = onSetDrivingPoint || (() => {});
    }

    onClick = () => {
        const location = this._map.getCenter();
        this.onSetDrivingPoint(location);
    }

    render() {
        return (
            <div className="custom-map-control">
                <button onClick={this.onClick}>
                    <IoCar className="mapbox-ctrl-icon" size="18px" />
                </button>
            </div>
        )
    }
}


export default DrivingPointControl;
