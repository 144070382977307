import React from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { IoSearch,IoClose } from 'react-icons/io5';

import './SearchInput.css';


class SearchInput extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {
            focussed: false,
            value: "",
        };

        this.handleFocus = this.handleFocus.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleFocus() {
        if (!this.props.focussed) {
            this.props.onFocus();
        }
    }

    handleChange(value) {
        this.props.onChange(value);
        this.setState({
            value,
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.focussed !== prevProps.focussed && this.searchInputRef) {
            if (this.props.focussed) {
                this.searchInputRef.focus();
            } else {
                this.searchInputRef.blur();
            }
        }
    }

    render() {
        return (
            <InputGroup
                className="search-input-group"
            >
                <InputGroup.Prepend onClick={() => this.searchInputRef.focus()}>
                    <InputGroup.Text className="search-icon left">
                        <IoSearch />
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                    className="search-input"
                    type="text"
                    name="search"
                    placeholder="Search for a mountain"
                    ref={ele => (this.searchInputRef = ele)}
                    onFocus={this.handleFocus}
                    onChange={(e) => this.handleChange(e.target.value)}
                    autoComplete="off"
                />
                { this.state.value &&
                    <InputGroup.Append onClick={() => {
                        const value = '';
                        this.searchInputRef.value = value;
                        this.handleChange(value);
                    }}>
                        <InputGroup.Text className="search-icon right">
                            <IoClose size="18px" />
                        </InputGroup.Text>
                    </InputGroup.Append>
                }
            </InputGroup>
        );
    }
}

SearchInput.defaultProps = {
    onFocus: () => {},
    focussed: false,
};

SearchInput.propTypes = {
    onFocus: PropTypes.func,
    focussed: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};


export default SearchInput;
