export const getWalksForSummit = (walks, summit) => {
    if (!walks || !summit) return [];

    if (summit.walkhighlands_routes.length > 0) {
        return summit.walkhighlands_routes.filter(route => route in walks).map(route => walks[route]);
    }

    return [];
};


export const createGoogleMapsUrl = ({latitude, longitude}) => `https://www.google.com/maps/dir/Current+Location/${latitude},${longitude}`;


export const formatDriveTime = (time) => {
    if (time < 60) {
        return `${time} min`
    } else {
        const hours = Math.floor(time / 60);
        const mins = time % 60;
        return `${hours} hr ${mins} min`;
    }
}

export const getRouteNumbers = (walks) => {
    const isEqual = (p1, p2) => {
        return p1.latitude === p2.latitude && p1.longitude === p2.longitude;
    }

    if (walks.length === 0) {
        return [];
    }

    const firstStartPoint = walks[0].start_point;

    if (walks.every(w => isEqual(w.start_point, firstStartPoint))) {
        // Don't number the routes if they are all the same starting point
        return walks.map(_ => null);
    }

    let startPointNumber = 1;
    let startPoint = firstStartPoint;

    // Number the start points, reusing the previous number if the points are equal
    const routeNumbers = walks.map(walk => {
        if (!isEqual(walk.start_point, startPoint)) {
            startPointNumber++;
            startPoint = walk.start_point;
        }

        return startPointNumber;
    });

    return routeNumbers;
};

export const isToday = (date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear();
};

export const addDays = (date, days) => {
    const result = new Date(date.getTime());
    result.setDate(date.getDate() + days);
    return result;
}

export const now = () => new Date();

export const toISODateString = (date) => date.toISOString().split('T')[0];

export const zip = (arr1, arr2) => arr1.reduce((acc, cur, i) => [...acc, cur, arr2[i]], []);
