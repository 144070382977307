import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';

import ScrollTopButton from '../ScrollTopButton';

import './Panel.css';

const Panel = ({ children, stickyHeader, flush, open, scrollTopButton, hidden }) => {
    
    const [isOpen, setOpen] = useState(open);

    useEffect(() => {
        if (!hidden && !isOpen) {
            setOpen(true);
        }
    }, [hidden]);  // eslint-disable-line react-hooks/exhaustive-deps

    const Icon = isOpen ? IoChevronBack : IoChevronForward;
    
    const panelWrapperClassNames = ["panel-wrapper"]
    if (isOpen) {
        panelWrapperClassNames.push("open");
    }
    if (hidden) {
        panelWrapperClassNames.push("hidden");
    }

    const panelRef = useRef();

    return (
        <div className={panelWrapperClassNames.join(" ")}>
            <div className="panel-content-wrapper">
                <div className="panel" ref={panelRef}>
                    <div className="sticky-header">
                        { stickyHeader }
                    </div>
                    
                    { scrollTopButton && panelRef.current ?
                        <ScrollTopButton
                            scrollContainerRef={panelRef}
                        />
                    : null }

                    <div className={flush ? "panel-body flush" : "panel-body"}>
                        { children }
                    </div>
                </div>
            </div>
            
            <Button variant="light" className="panel-toggle" onClick={() => setOpen(!isOpen)}>
                <Icon className="panel-toggle-icon" />
            </Button>
        </div>
    );
} 

Panel.defaultProps = {
    flush: false,
    open: true,
    scrollTopButton: false,
};

Panel.propTypes = {
    children: PropTypes.node.isRequired,
    stickyHeader: PropTypes.node,
    flush: PropTypes.bool,
    open: PropTypes.bool,
    scrollTopButton: PropTypes.bool,
};

export default Panel;
