
var isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)

const externalLinks = {
    privacyPolicyLink: 'https://www.freeprivacypolicy.com/live/2e7aaf7b-fcc7-479d-be34-53d724862928',
    cookiesPolicyLink: 'https://www.freeprivacypolicy.com/live/2284b18e-fdea-4dba-b953-bfd136ff481b',
    feedbackLink: 'https://feedback.userreport.com/197e4265-93b6-46cc-a4ff-4f2e2c1f5b83/',
}

const dev = {
    walksApiConfig: {
        // eslint-disable-next-line no-restricted-globals
        host: isMobile ? location.href.split("3000")[0] + "3000" : 'http://localhost:3000',
        walksPath: '/v1/walks',
        summitsPath: '/v1/summits',
        wikiPath: '/v1/wiki/',
        weather: {
            basePath: '/v1/weather/',
            onecallPath: '/onecall',
            currentPath: '/current',
        },
        travelTimePath: '/v1/traveltime/',
    },
    mapboxConfig: {
        publicAccessToken: process.env.REACT_APP_MAPBOX_PUBLIC_ACCESS_TOKEN,
    },
    externalLinks,
};

const prod = {
    walksApiConfig: {
        // eslint-disable-next-line no-restricted-globals
        host: `https://${location.hostname}`,
        walksPath: '/api/v1/walks',
        summitsPath: '/api/v1/summits',
        wikiPath: '/api/v1/wiki/',
        weather: {
            basePath: '/api/v1/weather/',
            onecallPath: '/onecall',
            currentPath: '/current',
        },
        travelTimePath: '/api/v1/traveltime/',
    },
    mapboxConfig: {
        publicAccessToken: process.env.REACT_APP_MAPBOX_PUBLIC_ACCESS_TOKEN,
    },
    externalLinks,
};

export default process.env.NODE_ENV === 'production' ? prod : dev;