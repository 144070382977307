import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { IoHeart, IoHeartDislike } from 'react-icons/io5';

import withProfile from '../../hocs/withProfile';

import './FavouriteButton.css';

const FavouriteButton = ({
    summitId,
    addFavouriteSummit,
    removeFavouriteSummit,
    isFavourite,
}) => {
    const favourite = isFavourite(summitId);

    const toggleFavourite = useCallback(() => {
        if (favourite) {
            removeFavouriteSummit(summitId);
        } else {
            addFavouriteSummit(summitId);
        }
    }, [summitId, favourite, addFavouriteSummit, removeFavouriteSummit]);

    let variant;
    let Icon;
    let text;
    if (favourite) {
        variant = "outline-danger";
        Icon = IoHeartDislike
        text = "Remove";
    } else {
        variant = "outline-danger";
        Icon = IoHeart;
        text = "Favourite";
    }

    return (
        <Button
            variant={variant}
            onClick={toggleFavourite}
            className="favourite-button"
        >
            <Icon
                size="24px"
            />
            { text }
        </Button>
    );
};

FavouriteButton.propTypes = {
    summitId: PropTypes.string.isRequired,
};

export default withProfile(FavouriteButton);
