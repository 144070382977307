import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';


const WikiSummary = ({ data }) => {
    const [showMore, setShowMore] = useState(false);

    const expandable = data.summary.split(" ").length >= 60;
    const summary = showMore || !expandable ? data.summary : data.summary.split(" ", 50).join(" ") + ' ...';
    const ExpandableIcon = showMore ? IoChevronUp : IoChevronDown

    return (
        <Card>
            <Card.Img variant="top" src={data.main_image} />
            <Card.Body>
                <Card.Text>
                    { summary }
                    { expandable ?
                        <Button
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            variant="link"
                            onClick={() => setShowMore(!showMore)}
                        >
                            { showMore ? "See less" : "See more"}
                            <ExpandableIcon style={{ marginLeft: '0.25rem' }} />
                        </Button>
                    : null}
                </Card.Text>
                <Button block variant="outline-info" href={data.url} target="_blank" style={{borderRadius: 'var(--button-border-radius)'}}>
                    Read full article on Wikipedia
                </Button>
            </Card.Body>
        </Card>
    )
};

WikiSummary.propTypes = {
    data: PropTypes.object.isRequired,
};

export default WikiSummary;
