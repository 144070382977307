
import React from 'react';
import PropTypes from 'prop-types';

import SearchInput from './SearchInput';
import SortingControls from './SortingControls';

import './SearchControls.css';

const SearchControls = ({
    onSearchInputChange,
    searchInputFocussed,
    drivingTimeAvailable,
    sortingEnabled,
    resultsCount,
    onSortChange,
    childNodes,
    openBottomSheet,
}) => {

    const formatResultsCount = count => {
        if (count === 1) {
            return "1 result";
        } else {
            return `${count} results`;
        }
    }

    return (
        <div className="search-controls">
            <SearchInput
                onFocus={openBottomSheet}
                focussed={searchInputFocussed}
                onChange={onSearchInputChange}
            />
            { childNodes }
            <div hide-on-bottom="true" className="sorting-controls-section">
                <SortingControls
                    onChange={onSortChange}
                    disabled={!sortingEnabled}
                    drivingTimeAvailable={drivingTimeAvailable}
                >
                    <div>{ formatResultsCount(resultsCount) }</div>
                </SortingControls>
            </div>
        </div>
    );
};

SearchControls.defaultProps = {
    openBottomSheet: () => {},
    onSortChange: () => {},
    sortingEnabled: true,
};

SearchControls.propTypes = {
    onSearchInputChange: PropTypes.func.isRequired,
    searchInputFocussed: PropTypes.bool,
    resultsCount: PropTypes.number.isRequired,
    drivingTimeAvailable: PropTypes.bool,
    onSortChange: PropTypes.func,
    sortingEnabled: PropTypes.bool,
    onSearchInputFocus: PropTypes.func,
    children: PropTypes.node,
};

export default SearchControls;
